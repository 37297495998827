import React, { memo } from 'react';
import { func } from 'prop-types';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import { REJECTED } from 'constants/actionStatusConstants';
import Input from 'components/common/Input';
import { login as loginValidations } from 'utils/constraints';
import { useStatus, useForm, useValidation, useTextInputProps } from 'hooks';
import { login } from 'state/actions/userActions';
import routes from 'constants/routesPaths';

import { Box, Card, Button, Typography, Link, styled } from '@mui/material';

const messages = defineMessages({
  email: { id: 'login.form.email' },
  password: { id: 'login.form.password' }
});

const fields = {
  email: 'email',
  password: 'password'
};

const Container = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  height: 'calc(100% - 9rem)'
});

const CardSignIn = styled(Card)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: '4.3rem 3.3rem 3.2rem 3.3rem',
  width: '55rem'
});

const LoginTitle = styled(Typography)({
  marginBottom: '0.6rem'
});

const ErrorMessage = styled(Typography)({
  fontSize: '1.6rem',
  color: '#EB5757',
  paddingTop: '0.6rem',
  paddingBottom: '0.6rem',
  marginTop: '-3.7rem'
});

const FormContainer = styled(Box)({
  width: '100%'
});

const SignUpMessage = styled(Typography)({
  marginBottom: '4.8rem'
});

export const LoginForm = ({ onSubmit }) => {
  const intl = useIntl();
  const { status, error } = useStatus(login);
  const validator = useValidation(loginValidations);
  const {
    values,
    errors,
    handleValueChange,
    handleSubmit,
    handleFocus,
    handleBlur,
    activeFields,
    touched
  } = useForm(
    {
      onSubmit,
      validator,
      validateOnBlur: true
    },
    [onSubmit]
  );

  const inputProps = useTextInputProps(
    handleValueChange,
    handleFocus,
    handleBlur,
    values,
    errors,
    activeFields,
    touched
  );

  return (
    <Container>
      <CardSignIn>
        <LoginTitle variant="h1">
          <FormattedMessage id="login.title" />
        </LoginTitle>
        <Box>
          <SignUpMessage variant="h2" color="initial">
            <FormattedMessage id="login.requireacc" />
            <Link href={routes.registerUser}>
              <FormattedMessage id="login.signup" />
            </Link>
            &nbsp;•&nbsp;
            <Link href={routes.recoverPassword}>
              <FormattedMessage id="recover.title" />
            </Link>
          </SignUpMessage>
        </Box>
        <FormContainer component="form" fullWidth onSubmit={handleSubmit}>
          {status === REJECTED && <ErrorMessage>{error}</ErrorMessage>}
          <Input
            name="Email"
            type="email"
            label={intl.formatMessage(messages.email)}
            {...inputProps(fields.email)}
          />
          <Input
            name="Password"
            type="password"
            label={intl.formatMessage(messages.password)}
            {...inputProps(fields.password)}
          />
          <Button variant="contained" fullWidth type="submit" sx={{ mt: '1.25vw' }}>
            <FormattedMessage id="login.form.submit" />
          </Button>
        </FormContainer>
      </CardSignIn>
    </Container>
  );
};

LoginForm.propTypes = {
  onSubmit: func.isRequired
};

export default memo(LoginForm);
