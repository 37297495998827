import { useSelector, shallowEqual } from 'react-redux';

const useDashboard = () =>
  useSelector(
    ({ dashboard: { companyDashboard, dealDashboard } }) => ({
      companyDashboard,
      dealDashboard
    }),
    shallowEqual
  );

export default useDashboard;
