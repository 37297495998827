import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Card, Divider, Link, Avatar, styled } from '@mui/material';
import NewDealDialog from 'components/deals/NewDealDialog';
import { useStatus, useCompanies } from 'hooks';
import { getIndustry, getUserCompanies } from 'state/actions/companyActions';
import { createDeal } from 'state/actions/dealsActions';
import { FULFILLED } from 'constants/actionStatusConstants';
import { addHttpIfMissing } from 'utils/formatUtils';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '5.8rem 6.4rem',
  width: '84%'
});

const Title = styled(Typography)({
  fontSize: '2.4rem',
  fontWeight: 'bold'
});

const Header = styled(Box)({
  alignItems: 'center',
  justifyContent: 'space-between',
  display: 'flex',
  width: '100%',
  marginBottom: '2rem'
});

const ShareButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: '3.4rem',
  fontSize: '1.4rem',
  padding: '1.4rem 1rem 1.4rem 1rem'
}));

const MyCompanyCard = styled(Card)({
  padding: '2.3rem 2.7rem 2.8rem 2.4rem',
  width: '100%',
  flexDirection: 'column',
  marginTop: '2.5rem',
  marginBottom: '2.5rem'
});

const MyCompanyElementsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '2.7rem'
});

const CompanyAvatar = styled(Avatar)(({ theme }) => ({
  width: '10rem',
  height: '10rem',
  backgroundColor: theme.palette.info.main
}));

const CompanyTitleBox = styled(Box)({
  display: 'flex',
  marginLeft: '2.9rem',
  flexDirection: 'column'
});

const CompanyTitle = styled(Typography)({
  fontSize: '3.6rem',
  fontWeight: '500'
});

const MyCompanyDivider = styled(Divider)({
  marginBottom: '2.5rem',
  width: '100%'
});

const CompanyLabel = styled(Typography)({
  fontSize: '1.6rem',
  fontWeight: '700',
  marginBottom: '1.6rem'
});

const CompanyText = styled(Typography)({
  fontSize: '1.6rem',
  fontWeight: '400',
  marginBottom: '2.8rem',
  maxWidth: '58.1rem'
});

const CompanyLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '1.6rem',
  fontWeight: '400',
  fontFamily: 'DM Sans'
}));

const MyCompany = () => {
  const [startDealDialogOpen, setStartDealDialogOpen] = useState(false);

  const { status: getUserCompaniesStatus } = useStatus(getUserCompanies);

  const { status: getIndustryStatus } = useStatus(getIndustry);
  const { status: createDealStatus } = useStatus(createDeal);

  const { company, industry } = useCompanies();

  useEffect(() => {
    if (createDealStatus === FULFILLED) {
      setStartDealDialogOpen(false);
    }
  }, [createDealStatus]);

  const handleClickOpen = () => {
    setStartDealDialogOpen(true);
  };

  const handleClose = () => {
    setStartDealDialogOpen(false);
  };

  return (
    <Container>
      <Header>
        <Title>My Company</Title>
        <ShareButton variant="contained" onClick={handleClickOpen}>
          SHARE
        </ShareButton>
      </Header>
      {getUserCompaniesStatus === FULFILLED && (
        <MyCompanyCard>
          <MyCompanyElementsBox>
            <CompanyAvatar />
            <CompanyTitleBox>
              <CompanyLabel>Organization</CompanyLabel>
              <CompanyTitle>{company.name}</CompanyTitle>
            </CompanyTitleBox>
          </MyCompanyElementsBox>
          {company.description && (
            <>
              <MyCompanyDivider />
              <CompanyLabel>About</CompanyLabel>
              <CompanyText>{company.description}</CompanyText>
            </>
          )}
          <MyCompanyDivider />
          {getIndustryStatus === FULFILLED && (
            <>
              <CompanyLabel>Main Industry</CompanyLabel>
              <CompanyText>{industry.name}</CompanyText>
              <MyCompanyDivider />
            </>
          )}
          <CompanyLabel>Company size</CompanyLabel>
          <CompanyText>{`${company.size} Employees`}</CompanyText>
          {company.website && (
            <>
              <MyCompanyDivider />
              <CompanyLabel>Website</CompanyLabel>
              <CompanyLink href={addHttpIfMissing(company.website)} underline="hover">
                {company.website}
              </CompanyLink>
            </>
          )}
        </MyCompanyCard>
      )}
      <NewDealDialog
        startDealDialogOpen={startDealDialogOpen}
        setStartDealDialogOpen={setStartDealDialogOpen}
        handleClose={handleClose}
      />
    </Container>
  );
};

export default MyCompany;
