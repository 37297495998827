import { useSelector, shallowEqual } from 'react-redux';

const useQuickbooks = () =>
  useSelector(
    ({ quickbooks: { authUrl, callback, profitAndLossReport } }) => ({
      authUrl,
      callback,
      profitAndLossReport
    }),
    shallowEqual
  );

export default useQuickbooks;
