import { useSelector, shallowEqual } from 'react-redux';

const useDeals = () =>
  useSelector(
    ({
      deals: {
        deal,
        companySharedDeals,
        updateSharedDeals,
        companyReceivedDeals,
        updateRecievedDeals
      }
    }) => ({
      deal,
      companySharedDeals,
      updateSharedDeals,
      companyReceivedDeals,
      updateRecievedDeals
    }),
    shallowEqual
  );

export default useDeals;
