import { useSelector, shallowEqual } from 'react-redux';

const useCompanies = () =>
  useSelector(
    ({
      companies: {
        companies,
        company,
        industries,
        industry,
        companyAccounts,
        companyUsers,
        user,
        userCompanies
      }
    }) => ({
      companies,
      company,
      industries,
      industry,
      companyAccounts,
      companyUsers,
      user,
      userCompanies
    }),
    shallowEqual
  );

export default useCompanies;
