import React, { useContext, memo } from 'react';
import { RegisterUserContext } from 'context/registerUser.context';
import { Box, styled } from '@mui/material';
import StepsIndicator from 'components/steps/StepsIndicator';
import StepsForm from 'components/steps/StepsForm';

const steps = [
  'Sign Up',
  'Confirm your Email',
  'Getting Started',
  'Company Information'
];

const StepsIndicatorContainer = styled(Box)({
  marginTop: '8.3rem',
  display: 'flex',
  flexDirection: 'column'
});

const RegisterUserContent = () => {
  const { step } = useContext(RegisterUserContext);

  return (
    <StepsIndicatorContainer>
      <StepsIndicator steps={steps} showStepsUpTo={2} currentStep={step} />
      <StepsForm />
    </StepsIndicatorContainer>
  );
};

export default memo(RegisterUserContent);
