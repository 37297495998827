import React, { useContext } from 'react';
import { RegisterUserContext } from 'context/registerUser.context';

import {
  Box,
  Card,
  Button,
  Typography,
  styled,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField
} from '@mui/material';
import styledEmotion from '@emotion/styled';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const CardGettingStarted = styled(Card)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: '4.3rem 3.3rem 3.2rem 3.3rem',
  width: '55rem'
});

const Container = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  height: 'calc(100% - 17.3rem)'
});

const FormTitle = styled(Typography)({
  marginBottom: '0.6rem'
});

const FormContainer = styled(Box)({
  marginTop: '4.8rem',
  width: '100%'
});

const AutocompleteInput = styled(Autocomplete)({
  fontSize: '1.6rem'
});

const SelectInputLabel = styled(InputLabel)({
  fontSize: '1.6rem'
});

const SelectInput = styled(Select)({
  fontSize: '1.6rem',
  marginBottom: '1.7rem'
});

const CustomMenuItem = styled(MenuItem)({
  fontSize: '1.6rem'
});

const Li = styledEmotion.li`
  font-size: 1.6rem;
`;

const GettingStarted = () => {
  const { step, setStep, company, setCompany } = useContext(RegisterUserContext);

  const handleChange = event => {
    setCompany({ ...company, role: event.target.value });
  };

  const companies = [];
  const filter = createFilterOptions();

  const settingCompany = (evt, value) => {
    if (typeof value === 'string') {
      setCompany({ ...company, name: value });
    } else if (value && value.inputValue) {
      setCompany({ ...company, name: value.inputValue });
    } else {
      setCompany({ ...company, name: value });
    }
  };

  return (
    <Container>
      <CardGettingStarted>
        <FormTitle variant="h1">Let’s get started!</FormTitle>
        <Typography variant="h2">Join an existing Company or start a new one.</Typography>
        <FormContainer component="form" fullWidth>
          <AutocompleteInput
            value={company.name}
            onChange={(event, newValue) => settingCompany(event, newValue)}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;
              const isExisting = options.some(option => inputValue === option.name);
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  inputValue,
                  name: `Create "${inputValue}"`
                });
              }
              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="select-company"
            options={companies}
            getOptionLabel={option => {
              if (typeof option === 'string') {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.name;
            }}
            renderOption={(props, option) => <Li {...props}>{option.name}</Li>}
            fullWidth
            freeSolo
            renderInput={params => <TextField {...params} label="Company Name" />}
          />
          <FormControl fullWidth sx={{ mt: '1.7rem' }}>
            <SelectInputLabel id="select-member-label">I am interested in...</SelectInputLabel>
            <SelectInput
              labelId="select-role-label"
              label="I am interested in ..."
              id="select-role"
              name="isBuyerOrSeller"
              value={company.role}
              onChange={handleChange}
            >
              <CustomMenuItem value="seller">Selling my company</CustomMenuItem>
              <CustomMenuItem value="buyer">Buying a company</CustomMenuItem>
            </SelectInput>
          </FormControl>
          <Button
            variant="contained"
            fullWidth
            onClick={() => setStep(step + 1)}
            sx={{ mt: '1.25vw' }}
          >
            Continue
          </Button>
        </FormContainer>
      </CardGettingStarted>
    </Container>
  );
};

export default GettingStarted;
