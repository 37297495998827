import { useSelector, shallowEqual } from 'react-redux';

const useIndicators = () =>
  useSelector(
    ({ indicators: { indicators, indicator } }) => ({
      indicators,
      indicator
    }),
    shallowEqual
  );

export default useIndicators;
