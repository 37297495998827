import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useDispatch, useStatus, useQuickbooks } from 'hooks';
import { Card, Box, Typography, Button, Link, Divider, styled } from '@mui/material';
import { func } from 'prop-types';
import XLSX from 'xlsx';
import Loader from 'react-loader-spinner';
import { humanFileSize } from 'utils/formatUtils';
import { errorHandler, generateOutputFromWorkbook } from 'utils/xlsxUtils';
import YearPickerDialog from 'components/steps/YearPickerDialog';
import { setQuickbooksYear } from 'state/actions/userActions';
import { auth } from 'state/actions/quickbooksActions';
import { FULFILLED, REJECTED } from 'constants/actionStatusConstants';
import DocumentItem from 'components/items/DocumentItem';
import { ReactComponent as QuickbooksLogo } from 'assets/quickbooks.svg';
import SpreadsheetDropzone from '../spreadsheetUploader/SpreadsheetDropzone';

const Container = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column'
});

const FormTitle = styled(Typography)({
  marginBottom: '2.1rem'
});

const FormSubtitle = styled(Typography)({
  marginBottom: '3.6rem',
  paddingRight: '3.9rem',
  paddingLeft: '3.9rem',
  textAlign: 'center',
  lineHeight: '2.8rem'
});

const UploadCard = styled(Card)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: '3.3rem',
  width: '55rem',
  minHeight: '52rem',
  marginBottom: '5.8rem'
});

const QuickbooksLogoContainer = styled(Box)({
  width: '26.4rem',
  height: '4rem',
  marginBottom: '3rem'
});

const OrContainer = styled(Box)({
  width: '26.4rem',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '3rem',
  flexDirection: 'row',
  justifyContent: 'space-between'
});

const OrDivider = styled(Divider)({
  width: '9.3rem',
  backgroundColor: 'black'
});

const QuickbooksButton = styled(Button)(({ theme }) => ({
  fontSize: '1.8rem',
  fontWeight: 'bold',
  backgroundColor: theme.palette.quickbooks.main,
  height: '4.8rem',
  width: '26.4rem',
  marginBottom: '3.6rem',
  '&:hover': {
    backgroundColor: theme.palette.quickbooks.dark
  }
}));

const UploadCsvButton = styled(Button)(({ theme }) => ({
  fontSize: '1.6rem',
  color: 'black',
  backgroundColor: theme.palette.secondary.main,
  height: '5.6rem',
  width: '26.4rem',
  marginBottom: '1.7rem',
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark
  }
}));

const ContinueButton = styled(Button)({
  fontSize: '1.6rem',
  height: '6.5rem',
  marginTop: '1rem',
  paddingTop: '2rem',
  paddingBottom: '2rem',
  width: '48.4rem'
});

const GoBackLink = styled(Link)(({ theme, visibilityFun }) => ({
  color: theme.palette.black.main,
  cursor: 'pointer',
  fontSize: '1.8rem',
  textDecorationColor: theme.palette.black.main,
  visibility: visibilityFun ? 'visible' : 'hidden'
}));

const SpreadsheetUploader = ({ onSubmit, goBack }) => {
  const [usingQuickbooks, setUsingQuickbooks] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadedData] = useState([]);
  const [loadedDocuments, setLoadedDocuments] = useState([]);
  const [loadedPercent, setLoadedPercent] = useState([]);
  const [yearPickDialogOpen, setYearPickDialogOpen] = useState(false);

  const dataRef = useRef();
  dataRef.current = loadedData;
  const documentsRef = useRef();
  documentsRef.current = loadedDocuments;
  const percentRef = useRef();
  percentRef.current = loadedPercent;

  const quickbooksAuthRequest = useDispatch(auth);
  const setQuickbooksYearRequest = useDispatch(setQuickbooksYear);
  const { status: quickbooksAuthStatus } = useStatus(auth);
  const { authUrl } = useQuickbooks();

  useEffect(() => {
    if (quickbooksAuthStatus === FULFILLED) {
      window.location.replace(authUrl);
    }
    if (quickbooksAuthStatus === REJECTED) {
      setLoading(false);
    }
  }, [quickbooksAuthStatus, authUrl]);

  const onClickQuickbooks = () => {
    if (!loading) {
      setYearPickDialogOpen(true);
    }
  };

  const onClickUploadCsv = () => {
    setUsingQuickbooks(false);
  };

  const handleYearPickDialogClosed = () => {
    setYearPickDialogOpen(false);
  };

  // Handles documents's loading bar processes
  // Currently, the loading bars are not real-time updating, and percentage loading
  // added to the item size.
  const onDocumentLoadProgress = useCallback(index => {
    return evt => {
      if (evt.lengthComputable) {
        const percentLoaded = Math.round((evt.loaded / evt.total) * 100);
        if (percentLoaded <= 100) {
          setLoadedPercent(currentPercents => {
            if (currentPercents.length <= index) {
              return [...currentPercents, percentLoaded];
            }
            const newPercents = [...currentPercents];
            newPercents[index] = percentLoaded;
            return newPercents;
          });
        }
      }
    };
  }, []);

  const onDocumentLoadStart = useCallback(
    file => {
      setLoadedPercent(currentPercents => [...currentPercents, 0]);
      setLoadedDocuments(currentDocs => [
        ...currentDocs,
        {
          fileName: file.name,
          fileSize: humanFileSize(file.size),
          loadedPercent: loadedPercent[documentsRef.current.length],
          className: 'loaded-document-item'
        }
      ]);
    },
    [loadedPercent]
  );

  const onDrop = useCallback(
    acceptedFiles => {
      // To enable multiple documents upload, comment next five lines and uncomment acceptedFiles.forEach wrapper.
      loadedData.splice(0, loadedData.length);
      setLoadedPercent([]);
      setLoadedDocuments([]);
      const file = acceptedFiles[0];
      const index = 0;
      // acceptedFiles.forEach((file, index) => {
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = errorHandler;
      reader.onprogress = onDocumentLoadProgress(documentsRef.current.length + index);
      reader.onloadstart = onDocumentLoadStart(file);
      reader.onload = () => {
        const workbook = XLSX.read(reader.result, {
          type: 'binary'
        });
        workbook.SheetNames.forEach(name => {
          const output = generateOutputFromWorkbook(workbook, name);
          if (output && !loadedData.includes(output)) {
            output.name = file.name;
            loadedData.push(output);
          }
        });
      };
      reader.readAsBinaryString(file);
      // });
    },
    [loadedData, onDocumentLoadStart, onDocumentLoadProgress]
  );

  const onContinue = useCallback(() => {
    if (dataRef.current.length) {
      onSubmit(dataRef.current);
    } else {
      alert(
        "The uploaded spreadsheets didn't contain any financial data. Please upload a different set of documents and try again."
      );
      setLoadedPercent([]);
      setLoadedDocuments([]);
    }
  }, [onSubmit]);

  const onYearPickSubmit = year => {
    setLoading(true);
    setYearPickDialogOpen(false);
    setQuickbooksYearRequest(year);
    quickbooksAuthRequest();
  };

  return (
    <Container>
      <UploadCard>
        {usingQuickbooks ? (
          <Container>
            <FormTitle variant="h1">Let’s set up your profile</FormTitle>
            <FormSubtitle variant="h2">
              You can choose to connect your Mather account to Quickbooks or upload your CSV file.
              Mather will never make your data public without your permission. Read our Terms &
              Conditions
            </FormSubtitle>
            <QuickbooksLogoContainer>
              <QuickbooksLogo />
            </QuickbooksLogoContainer>
            <QuickbooksButton variant="contained" onClick={onClickQuickbooks}>
              {loading ? (
                <Loader
                  style={{ marginTop: 10 }}
                  type="TailSpin"
                  color="white"
                  height={30}
                  width={30}
                  timeout={0}
                />
              ) : (
                <p> Connect to QuickBooks </p>
              )}
            </QuickbooksButton>
            <OrContainer>
              <OrDivider />
              <Typography variant="h2">or</Typography>
              <OrDivider />
            </OrContainer>
            <UploadCsvButton variant="contained" onClick={onClickUploadCsv}>
              Upload Excel
            </UploadCsvButton>
          </Container>
        ) : (
          <Container>
            <FormTitle variant="h1">Upload Financial Data</FormTitle>
            <FormSubtitle variant="h2">This will help us manage your financial data.</FormSubtitle>
            <SpreadsheetDropzone onDrop={onDrop} accept=".xlsx, .xls, .csv" />
            <Box className="loaded_documents_container">
              {loadedDocuments.map((document, index) => (
                <DocumentItem
                  key={`document${index}`}
                  fileName={document.fileName}
                  fileSize={document.fileSize}
                  loadedPercent={document.loadedPercent}
                  className={document.className}
                />
              ))}
            </Box>
            {loadedDocuments.length > 0 && loadedPercent.every(x => x >= 100) && (
              <ContinueButton variant="contained" onClick={onContinue}>
                Continue
              </ContinueButton>
            )}
          </Container>
        )}
      </UploadCard>
      {!usingQuickbooks ? (
        <GoBackLink
          visibilityFun={goBack}
          onClick={usingQuickbooks ? goBack : () => setUsingQuickbooks(true)}
        >
          Go back
        </GoBackLink>
      ) : null}
      <YearPickerDialog
        startDealDialogOpen={yearPickDialogOpen}
        handleClose={handleYearPickDialogClosed}
        onSubmit={onYearPickSubmit}
      />
    </Container>
  );
};

SpreadsheetUploader.propTypes = {
  onSubmit: func.isRequired,
  goBack: func
};

export default SpreadsheetUploader;
