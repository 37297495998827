import React, { memo } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, TableRow, styled } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { capitalize, dashboardToYearArray, stringIsYear } from 'utils/formatUtils';
import { MONTH_NAMES, QUARTER_NAMES } from 'constants/constants';
import { array, string } from 'prop-types';

const Container = styled(Box)({
  width: '100%',
  marginBottom: '5rem'
});

const TableContainerBox = styled(Box)({
  borderRadius: 1,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: '#E5E5E5'
});

const IndicatorMuiTable = styled(Table)({
  minWidth: '109rem'
});

const IndicatorTableHead = styled(TableHead)({
  height: '7rem'
});

const IndicatorYearTableCell = styled(TableCell)({
  fontSize: '1.6rem',
  fontWeight: 700
});

const IndicatorTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: '1.6rem',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    fontWeight: 500
  }
}));

const IndicatorTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.primary.light
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.common.white
  }
}));

const IndicatorTable = ({ data, timePeriodType }) => {
  const rows = dashboardToYearArray(data, timePeriodType);
  const valuesWithCommas = value => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  rows.forEach(row => {
    const { year, ...rowWithoutYear } = row;
    const total = Object.values(rowWithoutYear).reduce((a, b) => parseFloat(a) + parseFloat(b));
    row.total = valuesWithCommas(parseFloat(total).toFixed(2));
    Object.entries(rowWithoutYear).forEach(([key, value]) => {
      row[key] = valuesWithCommas(value);
    });
  });

  let columns;
  switch (timePeriodType) {
    case 'MONTHLY': {
      columns = MONTH_NAMES.slice(12, 24).map(month => capitalize(month));
      break;
    }
    case 'QUARTERLY': {
      columns = QUARTER_NAMES.slice(2, 6).map(quarter => capitalize(quarter));
      break;
    }
    case 'ANUALLY': {
      columns = data
        .filter(e => stringIsYear(e.year))
        .map(yearValue => yearValue.year)
        .sort();
      break;
    }
    default:
      break;
  }
  columns.push('Total');

  return (
    <Container>
      <TableContainer component={TableContainerBox}>
        <IndicatorMuiTable aria-label="customized table">
          <IndicatorTableHead>
            <TableRow>
              {timePeriodType !== 'ANUALLY' && <IndicatorTableCell />}
              {columns.map((col, index) => (
                <IndicatorTableCell align="center" key={`indicator-header-${index}`}>
                  {col}
                </IndicatorTableCell>
              ))}
            </TableRow>
          </IndicatorTableHead>
          <TableBody>
            {rows.map(
              (row, index) =>
                ({
                  MONTHLY: (
                    <IndicatorTableRow key={`indicator-row-${index}`}>
                      <IndicatorYearTableCell align="center" component="th" scope="row">
                        {row.year}
                      </IndicatorYearTableCell>
                      <IndicatorTableCell align="center"> {row['1'] || '(-)'} </IndicatorTableCell>
                      <IndicatorTableCell align="center"> {row['2'] || '(-)'} </IndicatorTableCell>
                      <IndicatorTableCell align="center"> {row['3'] || '(-)'} </IndicatorTableCell>
                      <IndicatorTableCell align="center"> {row['4'] || '(-)'} </IndicatorTableCell>
                      <IndicatorTableCell align="center"> {row['5'] || '(-)'} </IndicatorTableCell>
                      <IndicatorTableCell align="center"> {row['6'] || '(-)'} </IndicatorTableCell>
                      <IndicatorTableCell align="center"> {row['7'] || '(-)'} </IndicatorTableCell>
                      <IndicatorTableCell align="center"> {row['8'] || '(-)'} </IndicatorTableCell>
                      <IndicatorTableCell align="center"> {row['9'] || '(-)'} </IndicatorTableCell>
                      <IndicatorTableCell align="center"> {row['10'] || '(-)'} </IndicatorTableCell>
                      <IndicatorTableCell align="center"> {row['11'] || '(-)'} </IndicatorTableCell>
                      <IndicatorTableCell align="center"> {row['12'] || '(-)'} </IndicatorTableCell>
                      <IndicatorTableCell align="center"> {row.total || '(-)'} </IndicatorTableCell>
                    </IndicatorTableRow>
                  ),
                  QUARTERLY: (
                    <IndicatorTableRow key={`indicator-row-${index}`}>
                      <IndicatorYearTableCell align="center" component="th" scope="row">
                        {row.year}
                      </IndicatorYearTableCell>
                      <IndicatorTableCell align="center"> {row['1'] || '(-)'} </IndicatorTableCell>
                      <IndicatorTableCell align="center"> {row['2'] || '(-)'} </IndicatorTableCell>
                      <IndicatorTableCell align="center"> {row['3'] || '(-)'} </IndicatorTableCell>
                      <IndicatorTableCell align="center"> {row['4'] || '(-)'} </IndicatorTableCell>
                      <IndicatorTableCell align="center"> {row.total || '(-)'} </IndicatorTableCell>
                    </IndicatorTableRow>
                  ),
                  ANUALLY: (
                    <IndicatorTableRow key={`indicator-row-${index}`}>
                      {Object.keys(row)
                        .filter(e => stringIsYear(e))
                        .sort()
                        .map(key => (
                          <IndicatorTableCell align="center">
                            {' '}
                            {row[key] || '(-)'}{' '}
                          </IndicatorTableCell>
                        ))}
                      <IndicatorTableCell align="center"> {row.total || '(-)'} </IndicatorTableCell>
                    </IndicatorTableRow>
                  )
                }[timePeriodType])
            )}
          </TableBody>
        </IndicatorMuiTable>
      </TableContainer>
    </Container>
  );
};

IndicatorTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: array.isRequired,
  timePeriodType: string.isRequired
};

export default memo(IndicatorTable);
