import React from 'react';
import { Box } from '@mui/material';
import { string, object } from 'prop-types';

const ProgressBar = ({ bgcolor, completed }) => {
  const containerStyles = {
    height: 4,
    width: '100%',
    backgroundColor: '#989898',
    borderRadius: 50
  };

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: bgcolor,
    transition: 'width 1s ease-in-out',
    borderRadius: 'inherit',
    textAlign: 'right'
  };

  return (
    <Box style={containerStyles}>
      <Box style={fillerStyles} />
    </Box>
  );
};

ProgressBar.propTypes = {
  bgcolor: string.isRequired,
  completed: object.isRequired
};

export default ProgressBar;
