import React from 'react';
import { Button, Box, Typography, styled } from '@mui/material';
import Dropzone from 'react-dropzone';
import { ReactComponent as Docs } from 'assets/docs.svg';
import { string, func } from 'prop-types';

const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#F5F3F1',
  width: 484,
  height: 333,
  borderRadius: 3
});

const OuterBorders = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderWidth: 1,
  width: 440,
  height: 289,
  borderColor: '#ACACAC',
  borderRadius: 3,
  borderStyle: 'dashed',
  padding: 37
});

const InnerContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
  width: '100%'
});

const InstructionText = styled(Typography)({
  fontSize: 18,
  fontFamily: 'DM Sans',
  textAlign: 'center'
});

const SelectFilesButton = styled(Button)({
  paddingLeft: 80,
  paddingRight: 80,
  fontSize: 16,
  height: 65
});

const SpreadsheetDropzone = ({ onDrop, accept }) => {
  return (
    <Dropzone onDrop={onDrop} accept={accept}>
      {({ getRootProps, getInputProps }) => (
        <section>
          <Container {...getRootProps()}>
            <OuterBorders>
              <InnerContainer>
                <input {...getInputProps()} />
                <Docs height="74" width="61" />
                <InstructionText>Upload or Drag and Drop your file</InstructionText>
                <SelectFilesButton variant="contained" type="submit">
                  Select File
                </SelectFilesButton>
              </InnerContainer>
            </OuterBorders>
          </Container>
        </section>
      )}
    </Dropzone>
  );
};

SpreadsheetDropzone.propTypes = {
  onDrop: func.isRequired,
  accept: string.isRequired
};

export default SpreadsheetDropzone;
