import React, { memo } from 'react';
import { func } from 'prop-types';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import { REJECTED } from 'constants/actionStatusConstants';
import Input from 'components/common/Input';
import { recoverPassword as recoverPasswordValidations } from 'utils/constraints';
import { useStatus, useForm, useValidation, useTextInputProps } from 'hooks';
import { recoverPassword } from 'state/actions/userActions';
import routes from 'constants/routesPaths';

import { Box, Card, Button, Typography, Link, styled } from '@mui/material';

const messages = defineMessages({
  email: { id: 'recover.form.email' }
});

const fields = {
  email: 'email'
};

const Container = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  height: 'calc(100% - 9rem)'
});

const CardSignIn = styled(Card)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: '4.3rem 3.3rem 3.2rem 3.3rem',
  width: '55rem'
});

const RecoverPasswordTitle = styled(Typography)({
  marginBottom: '0.6rem'
});

const ErrorMessage = styled(Typography)({
  fontSize: '1.6rem',
  color: '#EB5757',
  paddingBottom: '0.6rem'
});

const FormContainer = styled(Box)({
  width: '100%'
});

const SignInMessage = styled(Typography)({
  marginBottom: '4.8rem'
});

export const RecoverPasswordForm = ({ onSubmit }) => {
  const intl = useIntl();
  const { status, error } = useStatus(recoverPassword);
  const validator = useValidation(recoverPasswordValidations);
  const {
    values,
    errors,
    handleValueChange,
    handleSubmit,
    handleFocus,
    handleBlur,
    activeFields,
    touched
  } = useForm(
    {
      onSubmit,
      validator,
      validateOnBlur: true
    },
    [onSubmit]
  );

  const inputProps = useTextInputProps(
    handleValueChange,
    handleFocus,
    handleBlur,
    values,
    errors,
    activeFields,
    touched
  );

  return (
    <Container>
      <CardSignIn>
        <RecoverPasswordTitle variant="h1">
          <FormattedMessage id="recover.title" />
        </RecoverPasswordTitle>
        <Box>
          <SignInMessage variant="h2" color="initial">
            <Link href={routes.login}>
              <FormattedMessage id="recover.login" />
            </Link>
          </SignInMessage>
        </Box>
        <FormContainer component="form" fullWidth onSubmit={handleSubmit}>
          {status === REJECTED && (
            <ErrorMessage>{error || <FormattedMessage id="recover.error" />}</ErrorMessage>
          )}
          <RecoverPasswordTitle variant="h2" color="initial">
            <FormattedMessage id="recover.form.title" />
          </RecoverPasswordTitle>
          <Input
            name="Email"
            type="email"
            label={intl.formatMessage(messages.email)}
            {...inputProps(fields.email)}
          />
          <Button variant="contained" fullWidth type="submit" sx={{ mt: '1.25vw' }}>
            <FormattedMessage id="recover.form.submit" />
          </Button>
        </FormContainer>
      </CardSignIn>
    </Container>
  );
};

RecoverPasswordForm.propTypes = {
  onSubmit: func.isRequired
};

export default memo(RecoverPasswordForm);
