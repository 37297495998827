import React, { useEffect } from 'react';
import { profitAndLossReport } from 'state/actions/quickbooksActions';
import { useQuickbooks, useDispatch, useStatus, useSession } from 'hooks';
import { Box, Card, Typography, styled } from '@mui/material';
import { generateOutputFromQuickbooks } from 'utils/xlsxUtils';
import Loader from "react-loader-spinner";
import { FULFILLED } from 'constants/actionStatusConstants';
import { string, func } from 'prop-types';

const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
});

const LoadingCard = styled(Card)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '55.2rem',
  height: '60.6rem'
});

const LoadingLabel = styled(Typography)({
  fontSize: '2.4rem',
  marginTop: '3.3rem'
});

const LoadingQuickbooks = ({ onLoad, realmId, accessToken }) => {
  const profitAndLossReportRequest = useDispatch(profitAndLossReport);
  const { status: profitAndLossReportStatus } = useStatus(profitAndLossReport);
  const { profitAndLossReport:report } = useQuickbooks();
  const { quickbooksYear } = useSession();

  useEffect(() => {
    profitAndLossReportRequest({ realmId, accessToken, year:quickbooksYear })
  }, [profitAndLossReportRequest]);
  
  useEffect(() => {
    if (profitAndLossReportStatus === FULFILLED) {
      const output = generateOutputFromQuickbooks(report)
      output.name = 'Quickbooks Data';
      onLoad(output)
    }
  }, [profitAndLossReportStatus]);

  return (
    <Container>
      <LoadingCard>
        <Container>
          <Loader
            type="TailSpin"
            color="#19A664"
            height={85}
            width={85}
            timeout={0}
          />
          <LoadingLabel>Working on your data...</LoadingLabel>
        </Container>
      </LoadingCard>
    </Container>
  );
};

LoadingQuickbooks.propTypes = {
  onLoad: func.isRequired,
  realmId: string.isRequired,
  accessToken: string.isRequired
};

export default LoadingQuickbooks;
