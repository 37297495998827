import React, { useState, useEffect } from 'react';
import { Box, styled } from '@mui/material';
import MyDealsContent from 'components/deals/MyDealsContent';
import MyCompany from 'components/dashboard/MyCompany';
import FinancialsUpload from 'components/dashboard/FinancialsUpload';
import LeftNavigation from 'components/dashboard/LeftNavigation';
import DashboardContainer from 'components/dashboard/DashboardContainer';
import {
  getCompany,
  getCompanies,
  getUserCompanies,
  getCompanyAccounts,
  getIndustry
} from 'state/actions/companyActions';
import { getIndicators } from 'state/actions/indicatorsActions';
import { getMetrics } from 'state/actions/metricsActions';
import { getCompanyDashboard } from 'state/actions/dashboardActions';
import { useDispatch, useStatus, useCompanies, useSession, useIndicators } from 'hooks';
import { FULFILLED } from 'constants/actionStatusConstants';
import { string } from 'prop-types';

const topNavList = ['Dashboard', 'My Deals', 'Financial Data Upload'];
const botNavList = ['My Company', 'Logout' /* , 'Account Settings' */];

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: 'auto'
});

const DashboardPage = ({ startingTab }) => {
  const [currentPage, setCurrentPage] = useState(startingTab);
  const [currentRefresh, setCurrentRefresh] = useState(false);

  const companiesRequest = useDispatch(getCompanies);
  const userCompaniesRequest = useDispatch(getUserCompanies);
  const companyRequest = useDispatch(getCompany);
  const industryRequest = useDispatch(getIndustry);
  const indicatorsRequest = useDispatch(getIndicators);
  const metricsRequest = useDispatch(getMetrics);
  const getCompanyAccountsRequest = useDispatch(getCompanyAccounts);
  const getCompanyDashboardRequest = useDispatch(getCompanyDashboard);

  const { status: getUserCompaniesStatus } = useStatus(getUserCompanies);
  const { status: getIndicatorsStatus } = useStatus(getIndicators);
  const { status: getCompanyStatus } = useStatus(getCompany);

  const { indicators } = useIndicators();
  const { company } = useCompanies();
  const { companyId } = useSession();

  useEffect(() => {
    companiesRequest();
  }, [companiesRequest]);

  useEffect(() => {
    userCompaniesRequest();
  }, [userCompaniesRequest]);

  useEffect(() => {
    indicatorsRequest();
  }, [indicatorsRequest]);

  useEffect(() => {
    metricsRequest();
  }, [metricsRequest]);

  useEffect(() => {
    if (getUserCompaniesStatus === FULFILLED && companyId) {
      getCompanyAccountsRequest(companyId);
    }
  }, [getCompanyAccountsRequest, getUserCompaniesStatus]);

  useEffect(() => {
    if (getUserCompaniesStatus === FULFILLED && companyId) {
      companyRequest(companyId);
    }
  }, [companyRequest, getUserCompaniesStatus]);

  useEffect(() => {
    if (getCompanyStatus === FULFILLED) {
      if (company?.industries) {
        industryRequest(company.industries[0].industryId);
      }
    }
  }, [industryRequest, company.industries, getCompanyStatus]);

  useEffect(() => {
    if (getUserCompaniesStatus === FULFILLED && getIndicatorsStatus === FULFILLED) {
      // TODO REFACTOR - Remove start and end period params once default universal
      // pull has been implemented on the api
      indicators.forEach(indicator => {
        getCompanyDashboardRequest({
          companyId,
          dashboardParams: {
            indicatorId: indicator.id,
            startPeriod: {
              timePeriod: 1,
              timePeriodType: 'MONTHLY',
              year: 0
            },
            endPeriod: {
              timePeriod: 12,
              timePeriodType: 'MONTHLY',
              year: 2021
            },
            groupBy: 'MONTHLY',
            timePeriodType: 'MONTHLY'
          },
          indicatorName: indicator.name
        });
        getCompanyDashboardRequest({
          companyId,
          dashboardParams: {
            indicatorId: indicator.id,
            startPeriod: {
              timePeriod: 1,
              timePeriodType: 'QUARTERLY',
              year: 0
            },
            endPeriod: {
              timePeriod: 4,
              timePeriodType: 'QUARTERLY',
              year: 2021
            },
            groupBy: 'QUARTERLY',
            timePeriodType: 'QUARTERLY'
          },
          indicatorName: indicator.name
        });
        getCompanyDashboardRequest({
          companyId,
          dashboardParams: {
            indicatorId: indicator.id,
            startPeriod: {
              timePeriod: 1,
              timePeriodType: 'ANUALLY',
              year: 0
            },
            endPeriod: {
              timePeriod: 1,
              timePeriodType: 'ANUALLY',
              year: 2021
            },
            groupBy: 'ANUALLY',
            timePeriodType: 'ANUALLY'
          },
          indicatorName: indicator.name
        });
      });
    }
  }, [
    getCompanyDashboardRequest,
    getUserCompaniesStatus,
    getIndicatorsStatus,
    indicators,
    companyId
  ]);

  return (
    <Container>
      <LeftNavigation
        topTabs={topNavList}
        bottomTabs={botNavList}
        currentTab={currentPage}
        setCurrentTab={setCurrentPage}
        setCurrentRefresh={setCurrentRefresh}
      />
      {currentPage === 'Dashboard' && <DashboardContainer navigateTo={setCurrentPage} />}
      {currentPage === 'My Deals' && (
        <MyDealsContent refreshState={currentRefresh} setRefreshState={setCurrentRefresh} />
      )}
      {currentPage === 'My Company' && <MyCompany />}
      {currentPage === 'Financial Data Upload' && (
        <FinancialsUpload refreshState={currentRefresh} setRefreshState={setCurrentRefresh} />
      )}
    </Container>
  );
};

DashboardPage.propTypes = {
  startingTab: string.isRequired
};

export default DashboardPage;
