import React, { useState, useMemo } from 'react';
import { styled, Box, Switch, FormGroup, Stack, Typography } from '@mui/material';
import { getCompanyDashboard } from 'state/actions/dashboardActions';
import { FULFILLED } from 'constants/actionStatusConstants';
import { useDashboard, useStatus } from 'hooks';
import Charts from './Charts';
import NoTableIndicators from './NoTableIndicators';
import NoCharts from './NoCharts';
import TableIndicators from './TableIndicators';
import { func } from 'prop-types';

const BoardContainer = styled(Box)({
  display: 'flex',
  width: '84%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '5.8rem 6.4rem'
});

const TopContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between'
});

const Title = styled(Typography)({
  fontSize: '2.4rem',
  lineHeight: '117%',
  fontWeight: 700
});

const SwitchText = styled(Typography)({
  fontSize: '1.8rem',
  lineHeight: '156%'
});

const DashboardContainer = ({ navigateTo }) => {
  const previousYear = new Date().getFullYear() - 1;
  const lastYear = new Date().getFullYear();
  const [view, setView] = useState(false);
  
  const { status: getCompanyDashboardStatus } = useStatus(getCompanyDashboard);
  const { companyDashboard } = useDashboard();

  const dashboardContainsThisOrLastYearData = useMemo(() => {
    return companyDashboard.some(x => x.data.some(y => y.year===lastYear || y.year===previousYear));
  }, [companyDashboard]);

  const handleView = () => {
    setView(!view);
  };

  const onUploadFinancialDataPress = () => {
    navigateTo('Financial Data Upload')
  };

  return (
    <BoardContainer>
      <TopContainer>
        <Title>Dashboard</Title>
        <FormGroup>
          <Stack direction="row" spacing={1} alignItems="center">
            <SwitchText>Dashboard View</SwitchText>
            <Switch color="default" onChange={handleView} />
            <SwitchText>Table View</SwitchText>
          </Stack>
        </FormGroup>
      </TopContainer>
      {getCompanyDashboardStatus === FULFILLED && (view ? 
        (companyDashboard.length ?
          <TableIndicators /> : 
          <NoTableIndicators onButtonPress={onUploadFinancialDataPress}/>) : 
        (dashboardContainsThisOrLastYearData ? 
          <Charts chartsData={companyDashboard} /> : 
          <NoCharts onButtonPress={onUploadFinancialDataPress}/>))}
      
    </BoardContainer>
  );
};

DashboardContainer.propTypes = {
  navigateTo: func.isRequired
};

export default DashboardContainer;
