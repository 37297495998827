import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  styled,
  Dialog,
  Divider,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import IndicatorsCheckboxGroup from 'components/common/IndicatorsCheckboxGroup';
import { useDispatch, useCompanies, useStatus } from 'hooks';
import { getCompanies, getCompanyUsers } from 'state/actions/companyActions';
import Loader from 'react-loader-spinner';
import { PENDING } from 'constants/actionStatusConstants';
import { createDeal } from 'state/actions/dealsActions';
import { func, bool } from 'prop-types';

const StartDealButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  fontSize: '1.4rem',
  height: '3.6rem',
  padding: '1.4rem 1rem 1.4rem 1rem'
}));

const CancelButton = styled(Button)({
  borderColor: '#B4B3B3',
  borderWidth: 1,
  borderStyle: 'solid',
  height: '3.6rem',
  color: '#B4B3B3',
  backgroundColor: 'white',
  fontSize: '1.4rem',
  padding: '1.4rem 1rem 1.4rem 1rem',
  marginRight: '1.6rem'
});

const NewDealDialogTitle = styled(DialogTitle)({
  fontSize: '3.2rem',
  fontFamily: 'DM Serif Display',
  fontWeight: 'bold',
  padding: '3.9rem 3.3rem 2.3rem 3.3rem'
});

const NewDealIconButton = styled(IconButton)({
  position: 'absolute',
  right: 8,
  top: 8,
  color: 'black'
});

const NewDealDialogContent = styled(DialogContent)({
  padding: '0 3.3rem 0 3.3rem'
});

const NewDealDialogContentText = styled(DialogContentText)({
  fontSize: '1.8rem',
  fontFamily: 'DM Sans',
  marginRight: '4.8rem',
  color: 'black'
});

const NewDealDialogDivider = styled(Divider)({
  marginTop: '2.6rem',
  marginBottom: '2.6rem'
});

const NewDealAutocompleteInput = styled(Autocomplete)({
  fontSize: '1.6rem',
  marginTop: '1rem',
  marginBottom: '1.7rem'
});

const NewDealCompanyOption = styled(Typography)({
  fontSize: '1.6rem'
});

const NewDealTextField = styled(TextField)({
  fontSize: '1.6rem'
});

const NewDealDialogActions = styled(DialogActions)({
  padding: '3.7rem 3.3rem'
});

const NewDealDialog = ({ startDealDialogOpen, handleClose }) => {
  const [selectedIndicators, setSelectedIndicators] = useState([]);
  const [selectedIndicatorsError, setSelectedIndicatorsError] = useState(null);
  const [company, setCompany] = useState(null);
  const [companyError, setCompanyError] = useState(null);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);

  const companyUsersRequest = useDispatch(getCompanyUsers);
  const createDealRequest = useDispatch(createDeal);
  const getCompaniesRequest = useDispatch(getCompanies);

  const { status: createDealStatus } = useStatus(createDeal);

  const { companies, companyUsers, userCompanies } = useCompanies();

  useEffect(() => {
    if (startDealDialogOpen) {
      getCompaniesRequest();
    }
    return () => {
      setSelectedIndicators([]);
      setCompany(null);
      setEmail('');
    };
  }, [getCompaniesRequest, startDealDialogOpen]);

  const handleCompanyChange = (event, newValue) => {
    setCompanyError(null);
    setCompany(newValue);
    companyUsersRequest(newValue.id);
  };

  const handleEmailChange = ({ target: { value } }) => {
    setEmailError(null);
    setEmail(value);
  };

  const handleShareDeal = () => {
    if (createDealStatus !== PENDING) {
      if (!company) {
        setCompanyError('Please select the company with which you want to share this deal');
      } else if (!email) {
        setEmailError('Please input the email of the user with which you want to share this deal');
      } else if (!companyUsers.some(user => email === user.email)) {
        setEmailError(
          'The email you have provided does not correspond to one of the company members'
        );
      } else if (selectedIndicators.length <= 0) {
        setSelectedIndicatorsError(
          'Please select at least one indicator to showcase while sharing this deal'
        );
      } else {
        const today = new Date();
        const expiration = new Date(today.getTime() + 15778800000);
        createDealRequest({
          companyId: userCompanies[0].company.id,
          newDeal: {
            receivingCompanyId: company.id,
            receivingCompanyUserId: companyUsers.find(user => email === user.email).id,
            startDate: today.toISOString(),
            expiration: expiration.toISOString(),
            indicatorsIds: selectedIndicators
          }
        });
      }
    }
  };

  return (
    <Dialog open={startDealDialogOpen} onClose={handleClose}>
      <NewDealDialogTitle>
        <>Start a new deal</>
        <NewDealIconButton aria-label="close" color="black" onClick={handleClose}>
          <CloseIcon />
        </NewDealIconButton>
      </NewDealDialogTitle>
      <NewDealDialogContent>
        <NewDealDialogContentText>
          Choose which information you want to share with other parties.
        </NewDealDialogContentText>
        <NewDealDialogDivider />
        <IndicatorsCheckboxGroup
          selectedIndicators={selectedIndicators}
          setSelectedIndicators={setSelectedIndicators}
          error={selectedIndicatorsError}
          setError={setSelectedIndicatorsError}
        />
        <NewDealDialogDivider />
        <NewDealDialogContentText>
          Enter the Company and the Email of the user you want to share your information with.
        </NewDealDialogContentText>
        <NewDealAutocompleteInput
          autoComplete
          fullWidth
          id="company"
          value={company}
          onChange={handleCompanyChange}
          options={companies}
          getOptionLabel={({ name }) => name}
          renderOption={(props, { name }) => (
            <NewDealCompanyOption {...props}> {name} </NewDealCompanyOption>
          )}
          renderInput={params => {
            params.InputProps.disableUnderline = true;
            params.InputLabelProps.shrink = true;
            return (
              <NewDealTextField
                {...params}
                label="Company"
                variant="filled"
                error={companyError != null}
                helperText={companyError}
              />
            );
          }}
        />
        <NewDealTextField
          fullWidth
          id="email"
          label="Email"
          variant="filled"
          value={email}
          onChange={handleEmailChange}
          error={emailError != null}
          helperText={emailError}
          InputProps={{
            disableUnderline: true
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      </NewDealDialogContent>
      <NewDealDialogActions>
        <CancelButton variant="contained" color="error" onClick={handleClose}>
          CANCEL
        </CancelButton>
        <StartDealButton variant="contained" onClick={handleShareDeal}>
          {createDealStatus === PENDING ? (
            <Loader
              style={{ marginTop: 10 }}
              type="TailSpin"
              color="white"
              height={18}
              width={18}
              timeout={0}
            />
          ) : (
            <> SHARE </>
          )}
        </StartDealButton>
      </NewDealDialogActions>
    </Dialog>
  );
};

NewDealDialog.propTypes = {
  startDealDialogOpen: bool.isRequired,
  handleClose: func.isRequired
};

export default NewDealDialog;
