import React, { useCallback, useContext, useEffect } from 'react';
import { createCompany } from 'state/actions/companyActions';
import { SignUpForm } from 'components/steps/SignUpForm';
import ConfirmForm from 'components/steps/ConfirmForm';
import GettingStartedForm from 'components/steps/GettingStartedForm';
import CompanyForm from 'components/steps/CompanyForm';
import { RegisterUserContext } from 'context/registerUser.context';
import { useDispatch, useStatus } from 'hooks';
import routes from 'constants/routesPaths';
import { useHistory } from 'react-router-dom';
import { signUp } from 'state/actions/userActions';
import { FULFILLED } from 'constants/actionStatusConstants';
import { Box, styled } from '@mui/material';

const Container = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingTop: '4rem',
  paddingBottom: '4rem'
});

const StepsForm = () => {
  const history = useHistory();

  const signUpRequest = useDispatch(signUp);
  const createCompanyRequest = useDispatch(createCompany);

  const { status: signUpStatus } = useStatus(signUp);
  const { status: createCompanyStatus } = useStatus(createCompany);

  const { step, setStep, setEmail, company } = useContext(RegisterUserContext);

  useEffect(() => {
    if (signUpStatus === FULFILLED) {
      setStep(step + 1);
    }
  }, [signUpStatus]);

  useEffect(() => {
    if (createCompanyStatus === FULFILLED) {
      if (company.role === 'seller') {
        history.push(routes.dashBoard_financial);
      } else {
        history.push(routes.dashBoard);
      }
    }
  }, [createCompanyStatus]);

  const onSignUp = useCallback(
    user => {
      setEmail(user.email);
      signUpRequest(user);
    },
    [setEmail, signUpRequest]
  );

  const goBack = () => {
    setStep(step - 1);
  };

  return (
    <Container>
      {step === 0 && <SignUpForm onSubmit={onSignUp} />}
      {step === 1 && <ConfirmForm />}
      {step === 2 && <GettingStartedForm />}
      {step === 3 && <CompanyForm onSubmit={createCompanyRequest} goBack={goBack} />}
    </Container>
  );
};

export default StepsForm;
