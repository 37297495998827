import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import { number, string } from 'prop-types';

const Container = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  marginBottom: '3.3rem'
});

const StepCircle = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '1.9rem',
  color: theme.palette.white.main,
  fontSize: '2rem',
  fontFamily: 'DM Sans',
  display: 'flex',
  justifyContent: 'center',
  height: '3.8rem',
  width: '3.8rem'
}));

const StepText = styled(Typography)({
  paddingLeft: '2.1rem',
  fontSize: '1.8rem'
});

const Step = ({ stepNumber, stepText }) => {
  return (
    <Container>
      <StepCircle>{stepNumber}</StepCircle>
      <StepText>{stepText}</StepText>
    </Container>
  );
};

Step.propTypes = {
  stepNumber: number.isRequired,
  stepText: string.isRequired
};

export default Step;
