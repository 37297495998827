import * as React from 'react';
import { Box, Divider, Drawer, List, ListItem, ListItemText, Toolbar, styled } from '@mui/material';
import { array, string, func } from 'prop-types';
import { logout } from 'state/actions/userActions';
import { useDispatch } from 'hooks';
import logo from '../../assets/logoMather.svg';

const DrawerContainer = styled(Box)({
  minWidth: '16.67vw'
});

const DrawerNav = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: '16.67vw',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.secondary.main
  }
}));

const LogoContainer = styled(Toolbar)({
  padding: 0
});

const Logo = styled('img')({
  display: 'flex',
  width: '100%',
  height: 'auto',
  content: `url(${logo})`,
  padding: '2.99vw 1.94vw'
});

const ButtonList = styled(ListItem)({
  padding: '0.69vw 2.01vw'
});

const Button = styled(ListItemText)({
  fontSize: '1.8rem',
  fontHeight: '156%'
});

const DividerSpaced = styled(Divider)({
  marginBottom: '1.39vw'
});

const LeftNavigation = ({ topTabs, bottomTabs, currentTab, setCurrentTab, setCurrentRefresh }) => {
  const logoutRequest = useDispatch(logout);

  const handleTabChange = text => {
    if (currentTab === text) {
      setCurrentRefresh(true);
    } else {
      if (text === 'Logout') logoutRequest();
      setCurrentTab(text);
    }
  };

  return (
    <DrawerContainer component="nav">
      <DrawerNav variant="permanent" open>
        <Box>
          <LogoContainer>
            <Logo alt="Mather" />
          </LogoContainer>
          <List>
            {topTabs.map(text => (
              <ButtonList button key={text} onClick={() => handleTabChange(text)}>
                <Button primary={text} />
              </ButtonList>
            ))}
          </List>
        </Box>
        <List>
          <DividerSpaced />
          {bottomTabs.map(text => (
            <ButtonList button key={text} onClick={() => handleTabChange(text)}>
              <Button primary={text} />
            </ButtonList>
          ))}
        </List>
      </DrawerNav>
    </DrawerContainer>
  );
};

LeftNavigation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  topTabs: array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  bottomTabs: array.isRequired,
  currentTab: string.isRequired,
  setCurrentTab: func.isRequired,
  setCurrentRefresh: func.isRequired
};

export default LeftNavigation;
