import React, { memo } from 'react';
import { Redirect } from 'react-router-dom';

import { useDispatch, useStatus } from 'hooks';
import RecoverPasswordForm from 'components/user/RecoverPasswordForm';
import { recoverPassword } from 'state/actions/userActions';
import routes from 'constants/routesPaths';
import { FULFILLED } from 'constants/actionStatusConstants';

const RecoverPasswordPage = () => {
  const recoverPasswordRequest = useDispatch(recoverPassword);
  const { status: recoverpasswordStatus } = useStatus(recoverPassword);

  if (recoverpasswordStatus === FULFILLED) {
    return <Redirect to={{ pathname: routes.dashBoard }} />;
  }

  return <RecoverPasswordForm onSubmit={recoverPasswordRequest} />;
};

export default memo(RecoverPasswordPage);
