import { useSelector, shallowEqual } from 'react-redux';

const useMetrics = () =>
  useSelector(
    ({ metrics: { metrics } }) => ({
      metrics
    }),
    shallowEqual
  );

export default useMetrics;
