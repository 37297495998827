import React, { useState } from 'react';
import { Menu, MenuItem, IconButton, styled } from '@mui/material';
import { useDispatch } from 'hooks';
import { cancelDeal } from 'state/actions/dealsActions';
import MoreVert from '@mui/icons-material/MoreVert';
import { number } from 'prop-types';

export const OptionsIconButton = styled(IconButton)({
  fontSize: '2rem'
});

const CancelDealItem = styled(MenuItem)({
  color: '#EB5757',
  fontSize: '1.4rem'
});

const DealsOptionsPicker = ({ dealId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const cancelDealRequest = useDispatch(cancelDeal);

  const displayOptions = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleDealCancel = event => {
    cancelDealRequest(dealId);
    handleClose(event);
  };

  return (
    <>
      <OptionsIconButton
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open}
        color="black"
        onClick={displayOptions}
      >
        <MoreVert />
      </OptionsIconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <CancelDealItem onClick={handleDealCancel}>Cancel Deal</CancelDealItem>
      </Menu>
    </>
  );
};

DealsOptionsPicker.propTypes = {
  dealId: number.isRequired
};

export default DealsOptionsPicker;
