import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Box, Typography, styled } from '@mui/material';
import { array, number, any, string } from 'prop-types';

const MetricsSelect = styled(Select)({
  height: 30,
  backgroundColor: 'white'
});

const MetricOption = styled(Typography)({
  textAlign: 'left',
  marginLeft: '0.6rem',
  fontSize: '1.4rem',
  paddingTop: '0.2rem'
});

const MetricItemContainer = styled(Box)({
  display: 'flex',
  paddingTop: 2,
  alignItems: 'center',
  justifyContent: 'left'
});

const MetricItemColorDot = styled(Box)({
  width: 9,
  height: 9,
  borderRadius: '50%',
  marginRight: '1rem',
  marginLeft: '1rem'
});

const MetricItemText = styled(Typography)({
  fontSize: '1.4rem',
  maxWidth: '11rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
});

const FinancialMetricSelect = ({ id, api, field, cellMode, value, metrics }) => {
  const handleOnClick = () => {
    api.setCellMode(id, field, cellMode === 'edit' ? 'view' : 'edit');
  };

  return (
    <MetricsSelect
      fullWidth
      onClick={handleOnClick}
      readOnly
      id="choose-metric"
      placeholder="Choose Metric"
      value={value || 0}
      renderValue={value => {
        const selectedMetric = metrics.find(metric => value == metric.id);
        if (selectedMetric) {
          return (
            <MetricItemContainer>
              <MetricItemColorDot
                style={{
                  backgroundColor: selectedMetric.hexColor
                }}
              />
              <MetricItemText>{selectedMetric.name}</MetricItemText>
            </MetricItemContainer>
          );
        }
        return <MetricOption> {'Choose Metric'} </MetricOption>;
      }}
      inputProps={{ 'aria-label': 'Without label' }}
    >
      <MenuItem key={-1} value={0}>
        <MetricOption> Choose Metric </MetricOption>
      </MenuItem>
      {metrics.map(({ id: metricId, name }) => (
        <MenuItem key={metricId} value={metricId}>
          <MetricOption> {name} </MetricOption>
        </MenuItem>
      ))}
    </MetricsSelect>
  );
};

FinancialMetricSelect.propTypes = {
  id: number.isRequired,
  api: any.isRequired,
  field: string.isRequired,
  cellMode: string.isRequired,
  value: number.isRequired,
  metrics: array.isRequired
};

export default FinancialMetricSelect;
