import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Box, Typography, styled } from '@mui/material';
import { array, number, any, string, func } from 'prop-types';

const MetricsSelect = styled(Select)({
  margin: '1rem',
  maxWidth: '17.2rem'
});

const MetricOption = styled(Typography)({
  textAlign: 'left',
  fontSize: '1.4rem',
  paddingTop: '0.2rem',
  paddingLeft: '0.5rem'
});

const MetricItemContainer = styled(Box)({
  display: 'flex',
  paddingTop: 2,
  alignItems: 'center',
  justifyContent: 'left'
});

const MetricItemColorDot = styled(Box)({
  width: 9,
  height: 9,
  borderRadius: '50%',
  marginRight: 11,
  marginLeft: 11
});

const MetricItemSelectedText = styled(Typography)({
  fontSize: '1.4rem',
  maxWidth: '11rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
});

const MetricItemText = styled(Typography)({
  fontSize: '1.4rem'
});

const SelectMetricInputCell = ({
  id,
  value,
  api,
  field,
  metrics,
  rows,
  selectedRowsIndexes,
  setRows
}) => {
  const handleClose = () => {
    api.setCellMode(id, field, 'view');
  };

  const handleSetCellValue = (id, api, field, value, event) => {
    api.setEditCellValue({ id, field, value }, event);
    api.commitCellChange({ id, field });
    api.setCellMode(id, field, 'view');
  };
  const handleChange = event => {
    /**
     * Behaviour: Selecting a specific metric on a row,
     * authomatically de-selects al similar metric values ("fileName" property)
     * from the rows in which the same metric has already been selected.
     */

    // const rowsRef = rows
    // if(rowsRef.some(x=> x.fileName == event.target.value)){
    //   rowsRef.forEach( (row) => {

    //     if(row.fileName == event.target.value){
    //       row.fileName = 0
    //       const id = row.id
    //       api.setEditCellValue({ id, field, value: 0 }, event);
    //       api.commitCellChange({ id, field });
    //       api.setCellMode(id, field, 'view');
    //     }
    //   })
    // }

    /**
     * Behaviour (correct one): When multiple rows are selected,
     * choosing a metric for one of the selected rows authomatically selects that metric
     * for all the selected rows. Then de-selects all rows.
     */
    const rowsRef = rows;
    if (selectedRowsIndexes.length && selectedRowsIndexes.includes(id)) {
      selectedRowsIndexes.forEach(index => {
        rows[index].fileName = event.target.value;
        const { id } = rows[index];
        handleSetCellValue(id, api, field, event.target.value, event);
      });
      api.setSelectionModel([]);
    } else {
      handleSetCellValue(id, api, field, event.target.value, event);
      rowsRef[id].fileName = event.target.value;
    }
    setRows(rowsRef);

    api.setCellMode(id, field, 'view');
  };

  return (
    <MetricsSelect
      fullWidth
      open
      id="choose-metric"
      value={value || 0}
      placeholder="Choose Metric"
      onChange={handleChange}
      onClose={handleClose}
      renderValue={value => {
        const selectedMetric = metrics.find(metric => value === metric.id);
        if (selectedMetric) {
          return (
            <MetricItemContainer>
              <MetricItemColorDot
                style={{
                  backgroundColor: selectedMetric.hexColor
                }}
              />
              <MetricItemSelectedText>{selectedMetric.name}</MetricItemSelectedText>
            </MetricItemContainer>
          );
        }
        return <MetricOption> {'Choose Metric'} </MetricOption>;
      }}
      inputProps={{ 'aria-label': 'Without label' }}
    >
      <MenuItem key={-1} value={0}>
        <MetricOption>Choose Metric</MetricOption>
      </MenuItem>
      {metrics.map(({ id: metricId, name, hexColor }) => (
        <MenuItem key={metricId} value={metricId}>
          <MetricItemContainer>
            <MetricItemColorDot
              style={{
                backgroundColor: hexColor
              }}
            />
            <MetricItemText>{name}</MetricItemText>
          </MetricItemContainer>
        </MenuItem>
      ))}
    </MetricsSelect>
  );
};

SelectMetricInputCell.propTypes = {
  id: number.isRequired,
  api: any.isRequired,
  field: string.isRequired,
  value: number.isRequired,
  metrics: array.isRequired,
  rows: array.isRequired,
  selectedRowsIndexes: array.isRequired,
  setRows: func.isRequired
};

export default SelectMetricInputCell;
