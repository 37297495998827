import React, { memo, useContext } from 'react';
import { func } from 'prop-types';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';

import { REJECTED } from 'constants/actionStatusConstants';

import Input from 'components/common/Input';
import { signUp as signUpValidations } from 'utils/constraints';
import { useStatus, useForm, useValidation, useTextInputProps } from 'hooks';
import { signUp } from 'state/actions/userActions';
import routes from 'constants/routesPaths';

import { Box, Card, Button, Typography, Link, styled } from '@mui/material';
import { RegisterUserContext } from 'context/registerUser.context';

const messages = defineMessages({
  firstName: { id: 'signup.form.firstName' },
  lastName: { id: 'signup.form.lastName' },
  email: { id: 'login.form.email' },
  password: { id: 'login.form.password' },
  passConfirmation: { id: 'signup.form.passconfirmation' }
});

const fields = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  password: 'password'
};

const CardSignUp = styled(Card)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: '4.3rem 3.3rem 3.2rem 3.3rem',
  width: '55rem'
});

const Container = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  height: 'calc(100% - 17.3rem)'
});

const SignUpTitle = styled(Typography)({
  marginBottom: '0.6rem'
});

const UserAgreementTerms = styled(Box)({
  marginBottom: '2.5rem',
  marginTop: '3.7rem'
});

const TermsText = styled(Typography)({
  textAlign: 'center'
});

const ErrorMessage = styled(Typography)({
  fontSize: '1.6rem',
  color: '#EB5757',
  paddingTop: '0.6rem',
  paddingBottom: '0.6rem',
  marginTop: '-3.7rem',
  width: '100%'
});

export const SignUpForm = ({ onSubmit }) => {
  const intl = useIntl();
  const { status, error } = useStatus(signUp);
  const { setEmail } = useContext(RegisterUserContext);

  const validator = useValidation(signUpValidations);
  const {
    values,
    errors,
    handleValueChange,
    handleSubmit,
    handleFocus,
    handleBlur,
    activeFields,
    touched
  } = useForm(
    {
      onSubmit,
      validator,
      validateOnBlur: true
    },
    [onSubmit]
  );

  const inputProps = useTextInputProps(
    handleValueChange,
    handleFocus,
    handleBlur,
    values,
    errors,
    activeFields,
    touched
  );

  const onHandleSubmit = user => {
    setEmail(user.email);
    handleSubmit(user);
  };

  return (
    <Container>
      <CardSignUp>
        <SignUpTitle variant="h1">
          <FormattedMessage id="signup.title" />
        </SignUpTitle>
        <Typography variant="h2" sx={{ mt: '0.42vw' }}>
          <FormattedMessage id="signup.member" />
          <Link href={routes.login}>
            <FormattedMessage id="signup.login" />
          </Link>
        </Typography>
        <Box component="form" fullWidth onSubmit={onHandleSubmit} sx={{ mt: '3.33vw' }}>
          {status === REJECTED && <ErrorMessage>{error}</ErrorMessage>}
          <Box display="flex" alignItems="center" justifyContent="center">
            <Input
              name="First Name"
              type="text"
              label={intl.formatMessage(messages.firstName)}
              sx={{
                marginRight: '0.63vw'
              }}
              {...inputProps(fields.firstName)}
            />
            <Input
              name="Last Name"
              type="text"
              label={intl.formatMessage(messages.lastName)}
              sx={{
                marginLeft: '0.63vw'
              }}
              {...inputProps(fields.lastName)}
            />
          </Box>
          <Input
            name="Email"
            type="email"
            label={intl.formatMessage(messages.email)}
            {...inputProps(fields.email)}
          />
          <Input
            name="Password"
            type="password"
            label={intl.formatMessage(messages.password)}
            {...inputProps(fields.password)}
          />
          <UserAgreementTerms>
            <TermsText variant="h3">
              By clicking Agree & Join, you agree to the Mather{' '}
              <Link href="">User Agreement, Privacy Policy,</Link> and{' '}
              <Link href="">Cookie Policy.</Link>
            </TermsText>
          </UserAgreementTerms>
          <Button variant="contained" fullWidth type="submit">
            <FormattedMessage id="signup.form.submit" />
          </Button>
        </Box>
      </CardSignUp>
    </Container>
  );
};

SignUpForm.propTypes = {
  onSubmit: func.isRequired
};

export default memo(SignUpForm);
