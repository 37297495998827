import React from 'react';
import ProgressBar from 'components/common/ProgressBar';
import { ReactComponent as Checkmark } from 'assets/checkmark.svg';
import { ReactComponent as Docs } from 'assets/docs.svg';
import { Box, Typography, styled } from '@mui/material';
import { string, number } from 'prop-types';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'start',
  margin: 21,
  paddingLeft: 21,
  paddingTop: 12,
  paddingRight: 17,
  paddingBottom: 17,
  borderColor: '#19A664',
  width: 484,
  borderRadius: 4,
  borderWidth: 1,
  borderStyle: 'solid'
});

const HorizontalDistributor = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  width: '100%'
});

const DocumentDataContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'start'
});

const DocsIconContainer = styled(Box)({
  height: 38,
  width: 30,
  marginBottom: 15,
  marginRight: 18
});

const DetailsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'start'
});

const TitleText = styled(Typography)({
  fontSize: 18,
  fontFamily: 'DM Sans',
  fontWeight: 10
});

const DataWeightText = styled(Typography)({
  fontSize: 14,
  fontFamily: 'DM Sans'
});

const CheckmarkContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: 38,
  width: 24,
  visibility: 'visible'
});

const DocumentItem = ({ fileName, fileSize, loadedPercent }) => {
  const item = (
    <Container>
      <HorizontalDistributor>
        <DocumentDataContainer>
          <DocsIconContainer>
            <Docs />
          </DocsIconContainer>
          <DetailsContainer>
            <TitleText> {fileName} </TitleText>
            <DataWeightText> {fileSize} </DataWeightText>
          </DetailsContainer>
        </DocumentDataContainer>
        {loadedPercent == 100 && (
          <CheckmarkContainer>
            <Checkmark />
          </CheckmarkContainer>
        )}
      </HorizontalDistributor>
      <ProgressBar bgcolor="#19A664" completed={loadedPercent} />
    </Container>
  );

  return item;
};

DocumentItem.propTypes = {
  fileName: string.isRequired,
  fileSize: string.isRequired,
  loadedPercent: number
};

export default DocumentItem;
