import React, { memo, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useSession, useDispatch, useStatus } from 'hooks';
import LoginForm from 'components/user/LoginForm';
import { login } from 'state/actions/userActions';
import routes from 'constants/routesPaths';
import { FULFILLED } from 'constants/actionStatusConstants';

const LoginPage = () => {
  const { authenticated, hasCompanies } = useSession();
  const loginRequest = useDispatch(login);
  const { status: loginStatus } = useStatus(login);
  const history = useHistory();

  useEffect(() => {
    if (authenticated && hasCompanies) {
      history.push(routes.dashBoard);
    }
  }, [authenticated, hasCompanies, history]);

  if (authenticated && loginStatus === FULFILLED) {
    if (hasCompanies) {
      return <Redirect to={routes.dashBoard} />;
    }
    return <Redirect to={{ pathname: routes.registerUser, state: { step: 2 } }} />;
  }

  return <LoginForm onSubmit={loginRequest} />;
};

export default memo(LoginPage);
