import React, { useState, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useMetrics } from 'hooks';
import { getFinantialTableColumns } from 'utils/financeTableUtils';
import { addAlpha, stringIsYear } from 'utils/formatUtils';
import FinancialMetricSelect from 'components/financialInfo/FinancialMetricSelect';
import SelectMetricInputCell from 'components/financialInfo/SelectMetricInputCell';
import { makeStyles } from '@mui/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, Typography, styled } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { MONTH_NAMES, QUARTER_NAMES } from 'constants/constants';
import { array, object, func, string } from 'prop-types';

const Container = styled(Box)({
  height: '60rem',
  width: '132rem',
  marginTop: '1rem',
  backgroundColor: 'white'
});

const Header = styled(Typography)({
  width: '100%',
  fontSize: '1.6rem',
  textAlign: 'center',
  minHeight: '5.5rem',
  paddingTop: '1.6rem'
});

const Cell = styled(Typography)({
  fontSize: '1.6rem',
  fontFamily: 'DM Sans',
  textAlign: 'center'
});

const CellTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'black',
    fontSize: '1.6rem',
    fontFamily: 'DM Sans',
    border: '1px solid gray'
  }
}));

const FinancialInfoSelectTable = ({
  data,
  rows,
  setRows,
  selectedPeriods,
  setSelectedPeriods,
  selectedFiscalStartPeriod
}) => {
  const { metrics } = useMetrics();

  const [selectedRowsIndexes, setSelectedRowsIndexes] = useState([]);

  const defaultTheme = createTheme({
    palette: {
      primary: { main: '#19A664' }
    }
  });
  const useStyles = makeStyles(
    () => {
      const style = {
        root: {}
      };
      metrics.forEach(metric => {
        style.root[`& .row-colored--${metric.hexColor.replace('#', '')}`] = {
          backgroundColor: addAlpha(metric.hexColor, 0.4),
          '&:hover': {
            backgroundColor: addAlpha(metric.hexColor, 0.5)
          }
        };
      });
      style.root[`& .MuiDataGrid-columnHeader`] = {
        backgroundColor: '#bdffe0',
        '&.deSelected': {
          backgroundColor: 'white'
        }
      };
      return style;
    },
    { defaultTheme }
  );

  const columns = getFinantialTableColumns(
    data,
    params => <FinancialMetricSelect metrics={metrics} {...params} />,
    props => (
      <SelectMetricInputCell
        metrics={metrics}
        rows={rows}
        setRows={setRows}
        selectedRowsIndexes={selectedRowsIndexes}
        {...props}
      />
    ),
    selectedFiscalStartPeriod >= 0 ? selectedFiscalStartPeriod : 0,
    params => {
      return <Header>{params.colDef.headerName}</Header>;
    },
    params => (
      <CellTooltip title={params.value}>
        <Cell>{params.value}</Cell>
      </CellTooltip>
    ),
    params => <Cell>{params.value}</Cell>
  );

  const handleColumnHeaderClick = (params, event) => {
    if (
      event.target.offsetParent.classList.contains('MuiDataGrid-columnHeader') &&
      (MONTH_NAMES.includes(String(params.colDef.field).toLowerCase()) ||
        QUARTER_NAMES.includes(String(params.colDef.field).toLowerCase()) ||
        stringIsYear(params.colDef.field))
    ) {
      if (
        event.target.offsetParent.classList.contains('deSelected') &&
        !selectedPeriods.includes(params.colDef.field)
      ) {
        event.target.offsetParent.classList.remove('deSelected');
        setSelectedPeriods(currentPeriods => [...currentPeriods, params.colDef.field]);
      } else {
        event.target.offsetParent.classList.add('deSelected');
        setSelectedPeriods(currentPeriods => currentPeriods.filter(x => x != params.colDef.field));
      }
    }
  };

  const handleDoubleCellClick = useCallback((params, event) => {
    event.defaultMuiPrevented = true;
  }, []);

  const handleCellKeyDown = useCallback(event => {
    if (['Escape', 'Delete', 'Backspace', 'Enter'].includes(event.key)) {
      event.defaultMuiPrevented = true;
    }
  }, []);

  const handleRowClassname = params => {
    if (params.row.fileName > 0) {
      return `row-colored--${metrics
        .find(metric => metric.id == params.row.fileName)
        .hexColor.replace('#', '')}`;
    }
    return '';
  };

  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <ThemeProvider theme={defaultTheme}>
        <DataGrid
          rows={rows}
          columns={columns}
          getRowClassName={handleRowClassname}
          onColumnHeaderClick={handleColumnHeaderClick}
          onCellDoubleClick={handleDoubleCellClick}
          onCellKeyDown={handleCellKeyDown}
          onSelectionModelChange={setSelectedRowsIndexes}
          showColumnRightBorder={false}
          showCellRightBorder={false}
          hideFooterSelectedRowCount
          checkboxSelection
          disableSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          disableColumnReorder
          disableColumnResize
          disableDensitySelector
        />
      </ThemeProvider>
    </Container>
  );
};

FinancialInfoSelectTable.propTypes = {
  data: object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rows: array.isRequired,
  setRows: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedPeriods: array.isRequired,
  setSelectedPeriods: func.isRequired,
  selectedFiscalStartPeriod: string
};

export default FinancialInfoSelectTable;
