import React, { useState } from 'react';
import { string, func } from 'prop-types';
import { Box, Typography, styled } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { ReactComponent as SelectArrow } from 'assets/selectArrow.svg';

const Container = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginRight: '2.5rem',
  fontSize: '1.6rem'
});

const DatePickerBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0.6rem',
  backgroundColor: 'white',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: '#E5E5E5',
  borderRadius: 4,
  minWidth: '17rem'
});

const DatePickerText = styled(Typography)({
  fontSize: '1.4rem',
  fontWeight: 400
});

const PickerArrowIcon = styled(SelectArrow)({
  marginLeft: '-2rem',
  zIndex: 10
});

const TimeUnitPicker = ({ variant, label, format, value, onChange }) => {
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          disableMaskedInput
          disableFuture
          views={[variant]}
          inputFormat={format}
          value={value}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onChange={onChange}
          renderInput={({ inputRef, inputProps }) => (
            <DatePickerBox
              onClick={() => {
                setOpen(true);
              }}
            >
              <DatePickerText ref={inputRef}>
                {inputProps.value ? inputProps.value : label}
              </DatePickerText>
              <PickerArrowIcon />
            </DatePickerBox>
          )}
        />
      </LocalizationProvider>
    </Container>
  );
};

TimeUnitPicker.propTypes = {
  variant: string.isRequired,
  label: string.isRequired,
  format: string.isRequired,
  value: string.isRequired,
  onChange: func.isRequired
};

export default TimeUnitPicker;
