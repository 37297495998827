import React from 'react';
import {
  styled,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox
} from '@mui/material';
import { useIndicators } from 'hooks';
import { func, array, string } from 'prop-types';

const NewDealFormControlLabel = styled(FormControlLabel)({
  fontSize: '1.8rem',
  fontFamily: 'DM Sans',
  padding: '0.5rem'
});

const IndicatorsFormHelperText = styled(FormHelperText)({
  fontSize: '1.6rem',
  fontFamily: 'DM Sans'
});

const IndicatorsCheckboxGroup = ({
  selectedIndicators,
  setSelectedIndicators,
  error,
  setError
}) => {
  const { indicators } = useIndicators();

  const handleCheckboxChange = ({ target: { checked, name } }) => {
    setError(null);
    if (checked) {
      setSelectedIndicators([...selectedIndicators, Number(name)]);
    } else {
      setSelectedIndicators(selectedIndicators.filter(indicator => indicator !== Number(name)));
    }
  };

  return (
    <FormControl error={error != null} component="fieldset" variant="standard">
      <FormGroup>
        {indicators.map(({ id: indicatorId, name }, index) => {
          return (
            <NewDealFormControlLabel
              key={`control-label-${index}`}
              control={
                <Checkbox
                  checked={selectedIndicators.includes(indicatorId)}
                  onChange={handleCheckboxChange}
                  name={String(indicatorId)}
                />
              }
              label={name}
            />
          );
        })}
      </FormGroup>
      {error != null && <IndicatorsFormHelperText>{error}</IndicatorsFormHelperText>}
    </FormControl>
  );
};

IndicatorsCheckboxGroup.propTypes = {
  selectedIndicators: array.isRequired,
  setSelectedIndicators: func.isRequired,
  error: string,
  setError: func.isRequired
};

export default IndicatorsCheckboxGroup;
