import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, styled } from '@mui/material';
import DealsList from 'components/deals/DealsList';
import DealDetails from 'components/deals/DealDetails';
import NoDeals from 'components/deals/NoDeals';
import NewDealDialog from 'components/deals/NewDealDialog';
import { useStatus, useDeals, useDispatch, useSession } from 'hooks';
import {
  createDeal,
  cancelDeal,
  getCompanySharedDeals,
  getCompanyRecievedDeals
} from 'state/actions/dealsActions';
import { FULFILLED } from 'constants/actionStatusConstants';
import { bool, func } from 'prop-types';

const Container = styled(Box)({
  display: 'flex',
  width: '84%',
  flexDirection: 'column',
  padding: '5.8rem 6.4rem'
});

const StartDealButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: '2.5vw',
  fontSize: '1.4rem',
  padding: '1.4rem 1rem 1.4rem 1rem'
}));

const PageTitle = styled(Typography)({
  fontSize: '2.4rem',
  fontWeight: 'bold'
});

const PageHeader = styled(Box)({
  alignItems: 'center',
  justifyContent: 'space-between',
  display: 'flex',
  width: '100%',
  marginBottom: '2rem'
});

const MyDealsContent = ({ refreshState, setRefreshState }) => {
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [startDealDialogOpen, setStartDealDialogOpen] = useState(false);

  const getCompanySharedDealsRequest = useDispatch(getCompanySharedDeals);
  const getCompanyRecievedDealsRequest = useDispatch(getCompanyRecievedDeals);

  const { status: getCompanySharedDealsStatus } = useStatus(getCompanySharedDeals);
  const { status: getCompanyRecievedDealsStatus } = useStatus(getCompanyRecievedDeals);
  const { status: createDealStatus } = useStatus(createDeal);
  const { status: cancelDealStatus } = useStatus(cancelDeal);

  const {
    companySharedDeals,
    companyReceivedDeals,
    updateSharedDeals,
    updateRecievedDeals
  } = useDeals();
  const { companyId } = useSession();

  useEffect(() => {
    if (refreshState) {
      setSelectedDeal(null);
      setStartDealDialogOpen(false);
      setRefreshState(false);
    }
  }, [refreshState, setRefreshState]);

  useEffect(() => {
    if (updateSharedDeals) {
      getCompanySharedDealsRequest(companyId);
    }
  }, [getCompanySharedDealsRequest, updateSharedDeals, updateRecievedDeals, companyId]);

  useEffect(() => {
    if (updateRecievedDeals) {
      getCompanyRecievedDealsRequest(companyId);
    }
  }, [getCompanyRecievedDealsRequest, updateSharedDeals, updateRecievedDeals, companyId]);

  useEffect(() => {
    if (createDealStatus === FULFILLED) {
      setSelectedDeal(null);
      setStartDealDialogOpen(false);
    }
  }, [createDealStatus]);

  useEffect(() => {
    if (cancelDealStatus === FULFILLED) {
      setSelectedDeal(null);
    }
  }, [cancelDealStatus]);

  const handleClickOpen = () => {
    setStartDealDialogOpen(true);
  };

  const handleClose = () => {
    setStartDealDialogOpen(false);
  };

  const handleDealClick = (deal, type) => {
    setSelectedDeal({ ...deal, type });
  };

  return (
    <Container>
      <PageHeader>
        <PageTitle> My Deals </PageTitle>
        {(companySharedDeals.length || companyReceivedDeals.length > 0) && (
          <StartDealButton variant="contained" onClick={handleClickOpen}>
            START A NEW DEAL
          </StartDealButton>
        )}
      </PageHeader>
      {companySharedDeals.length === 0 &&
        companyReceivedDeals.length === 0 &&
        getCompanySharedDealsStatus === FULFILLED &&
        getCompanyRecievedDealsStatus === FULFILLED && <NoDeals onButtonPress={handleClickOpen} />}
      {selectedDeal === null ? (
        <DealsList onDealClick={handleDealClick} />
      ) : (
        <DealDetails deal={selectedDeal} />
      )}
      <NewDealDialog
        startDealDialogOpen={startDealDialogOpen}
        setStartDealDialogOpen={setStartDealDialogOpen}
        handleClose={handleClose}
      />
    </Container>
  );
};

MyDealsContent.propTypes = {
  refreshState: bool.isRequired,
  setRefreshState: func.isRequired
};

export default MyDealsContent;
