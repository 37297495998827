import React, { useContext } from 'react';
import { resendConfirmationEmail } from 'state/actions/userActions';
import { useDispatch } from 'hooks';

import { Box, Card, Button, Typography, styled } from '@mui/material';
import { RegisterUserContext } from 'context/registerUser.context';

const Container = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  height: 'calc(100% - 17.3rem)'
});

const CardEmailConf = styled(Card)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: '4.3rem 3.3rem 3.2rem 3.3rem',
  width: '55rem'
});

const ResendLinkButton = styled(Button)({
  marginTop: '4.6rem'
});

const ConfirmForm = () => {
  const resendEmailRequest = useDispatch(resendConfirmationEmail);
  const { email } = useContext(RegisterUserContext);

  return (
    <Container>
      <CardEmailConf>
        <Typography variant="h1">Check your inbox!</Typography>
        <Typography variant="h2" sx={{ mt: '2.57vw' }}>
          We sent a confirmation link to {email}. Check your email for a link to continue setting
          your Mather account up.
        </Typography>
        <ResendLinkButton variant="contained" fullWidth onClick={() => resendEmailRequest(email)}>
          Resend Link
        </ResendLinkButton>
      </CardEmailConf>
    </Container>
  );
};

export default ConfirmForm;
