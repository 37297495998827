import React, { useEffect, useRef } from 'react';

import LandingPageFirstPart from 'components/landing/FirstPart';
import LandingPageSecondPart from 'components/landing/SecondPart';
import LandingPageThirdPart from 'components/landing/ThirdPart';
import LandingPageFourthPart from 'components/landing/FourthPart';
import { useLocation } from 'react-router';
import routesPaths from 'constants/routesPaths';

const LandingPage = () => {
  const { pathname } = useLocation();
  const aboutUsRef = useRef('');

  useEffect(() => {
    if (pathname === routesPaths.aboutUs) {
      aboutUsRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [pathname]);

  return (
    <>
      <LandingPageFirstPart />
      <LandingPageSecondPart aboutUsRef={aboutUsRef} />
      <LandingPageThirdPart />
      <LandingPageFourthPart />
    </>
  );
};

export default LandingPage;
