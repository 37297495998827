export const humanFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    u += 1;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return `${bytes.toFixed(dp)} ${units[u]}`;
};

export const addAlpha = (color, opacity) => {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};

export const capitalize = str => {
  const words = str.split(' ');
  let i = 0;
  while (i < words.length) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
    i += 1;
  }
  return words.join(' ');
};

export const addHttpIfMissing = url => {
  if (!/^https?:\/\//i.test(url)) {
    url = `http://${url}`;
  }
  return url;
};

export const dashboardToYearArray = (data, groupBy) => {
  const rows = [];
  if (groupBy === 'ANUALLY') {
    const row = {};
    data.forEach(({ year, value }) => {
      row[year] = value;
    });
    rows.push(row);
  } else {
    const years = [...new Set(data.map(item => item.year))];
    years.forEach(year => {
      const row = { year };
      data
        .filter(item => item.year === year)
        .forEach(data => {
          row[data.timePeriod] = data.value;
        });
      rows.push(row);
    });
  }
  return rows;
};

export const stringIsYear = string => {
  return (
    Number.isInteger(Number(string)) &&
    Number(string) >= 1960 &&
    Number(string) <= new Date().getFullYear()
  );
};

export const iff = (condition, then, otherwise) => (condition ? then : otherwise);
