import React, { useEffect } from 'react';
import { useSession, useDispatch, useStatus } from 'hooks';
import { Box, styled, AppBar, Button, Toolbar } from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
import routes from 'constants/routesPaths';
import { logout } from 'state/actions/userActions';
import { FULFILLED } from 'constants/actionStatusConstants';

import { createCompany } from 'state/actions/companyActions';
import logo from '../../assets/logoMather.svg';
import logoSolo from '../../assets/logoSolo.svg';

const HeaderContainer = styled(Box)({
  flexGrow: 1
});

const HeaderBar = styled(AppBar)(({ theme }) => ({
  boxShadow: 0,
  [theme.breakpoints.down('mobile')]: {
    height: 'auto',
    width: '100%'
  }
}));

const ToolBar = styled(Toolbar)(({ theme }) => ({
  justifyContent: 'space-between',
  [theme.breakpoints.down('mobile')]: {
    padding: '5.64vw 0vw 5.64vw 8.21vw'
  }
}));

const Logo = styled('img')(({ theme }) => ({
  display: 'flex',
  height: '2.08vw',
  margin: '2.08vw 0vw 2.08vw 6.39vw',
  content: `url(${logo})`,
  [theme.breakpoints.down('mobile')]: {
    width: '11.54vw',
    height: 'auto',
    margin: '0',
    content: `url(${logoSolo})`
  }
}));

const HeaderButtons = styled(Box)(({ theme }) => ({
  marginRight: '6.94vw',
  [theme.breakpoints.down('mobile')]: {
    display: 'none',
    justifyContent: 'flex-end',
    width: '100%',
    height: 'auto',
    marginRight: '4.1vw'
  }
}));

const LeftButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  color: theme.palette.primary.main,
  height: '2.5vw',
  marginRight: '1.11vw',
  letterSpacing: '0.125rem',
  '&:hover': {
    color: theme.palette.white.main
  },
  [theme.breakpoints.down('mobile')]: {
    fontSize: '1.4rem',
    lineHeight: '114%',
    marginRight: '4.1vw',
    padding: '2.56vw 3.59vw',
    height: '9.23vw',
    letterSpacing: '0.125rem'
  }
}));

const RightButton = styled(Button)(({ theme }) => ({
  height: '2.5vw',
  letterSpacing: '0.125rem',
  [theme.breakpoints.down('mobile')]: {
    fontSize: '1.4rem',
    lineHeight: '114%',
    padding: '2.56vw 3.59vw',
    height: '9.23vw'
  }
}));

const Header = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { authenticated } = useSession();
  const logoutRequest = useDispatch(logout);
  const { status: logoutStatus } = useStatus(logout);
  const { status: createCompanyStatus } = useStatus(createCompany);

  const loginText = 'Log in';
  const signupText = 'Sign up';
  const dashboardText = 'Dashboard';
  const logoutText = 'Logout';

  useEffect(() => {
    if (logoutStatus === FULFILLED) {
      history.push(routes.login);
    }
  }, [logoutStatus, history]);

  if (pathname.toLowerCase().includes(routes.dashBoard.toLowerCase())) return null;

  return (
    <HeaderContainer>
      <HeaderBar position="static" color="inherit">
        <ToolBar>
          <Logo alt="Mather" />
          <HeaderButtons>
            {pathname === routes.index && !authenticated && (
              <>
                <LeftButton variant="contained" href={routes.login}>
                  {loginText.toUpperCase()}
                </LeftButton>
                <RightButton variant="contained" href={routes.registerUser}>
                  {signupText.toUpperCase()}
                </RightButton>
              </>
            )}
            {authenticated && (
              <>
                {(createCompanyStatus === FULFILLED || pathname === routes.index) && (
                  <LeftButton variant="contained" href={routes.dashBoard}>
                    {dashboardText.toUpperCase()}
                  </LeftButton>
                )}
                <RightButton variant="contained" onClick={logoutRequest}>
                  {logoutText.toUpperCase()}
                </RightButton>
              </>
            )}
          </HeaderButtons>
        </ToolBar>
      </HeaderBar>
    </HeaderContainer>
  );
};

export default Header;
