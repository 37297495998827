import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { RegisterUserProvider } from 'context/registerUser.context';
import RegisterUserContent from './RegisterUserContent';

const RegisterUserPage = () => {
  const location = useLocation();
  const startingPage = location.state ? location.state.step : 0;

  return (
    <RegisterUserProvider startingPage={startingPage}>
      <RegisterUserContent />
    </RegisterUserProvider>
  );
};

export default memo(RegisterUserPage);
