import React, { memo, useState } from 'react';
import { Box, Typography, Button, Link, styled } from '@mui/material';
import { func, object, string } from 'prop-types';
import TimeUnitPicker from 'components/common/TimeUnitPicker';
import Step from 'components/steps/Step';
import { useCompanies, useSession } from 'hooks';
import FinancialInfoSelectTable from 'components/financialInfo/FinancialInfoSelectTable';
import { dispatchErrorToast } from 'utils/uiUtils';
import {
  getTimePeriodFromMonth,
  getTimePeriodFromQuarter,
  initialFinantialTableRows
} from 'utils/financeTableUtils';
import { stringIsYear } from 'utils/formatUtils';
import { MONTH_NAMES, QUARTER_NAMES } from 'constants/constants';

const Container = styled(Box)({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexWrap: 'wrap'
});

const TitleAndStepsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'start',
  width: '132rem',
  paddingBottom: '1rem'
});

const TitleText = styled(Typography)({
  fontSize: '3.6rem',
  fontFamily: 'DM Serif Display',
  paddingBottom: '1.7rem'
});

const YearMonthPickerBarContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  width: '132rem',
  paddingBottom: '1rem'
});

const TableTitleContainer = styled(Box)({
  marginLeft: '0.3rem',
  marginBottom: '-6.59rem',
  width: '132rem',
  zIndex: 10,
  pointerEvents: 'none'
});

const TableTitleBox = styled(Box)({
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  backgroundColor: 'white',
  height: '5.475rem',
  width: '40.6rem',
  overflow: 'inherit',
  borderTopLeftRadius: 3,
  borderWidth: 1,
  borderColor: '#e0e0e0',
  pointerEvents: 'visible'
});

const TableTitle = styled(Typography)({
  marginLeft: '1.4rem',
  fontSize: '1.8rem',
  fontFamily: 'DM Sans',
  fontWeight: 'bold',
  width: '39.2rem',
  maxLines: 2
});

const FooterBar = styled(Box)({
  display: 'flex',
  backgroundColor: '#F5F3F1',
  marginTop: '-5rem',
  paddingTop: '3.5rem',
  paddingBottom: '3.5rem',
  width: '132rem',
  justifyContent: 'space-between',
  alignItems: 'center',
  zIndex: 10
});

const GoBackLink = styled(Link)(({ theme }) => ({
  color: theme.palette.black.main,
  cursor: 'pointer',
  fontSize: '1.8rem',
  textDecorationColor: theme.palette.black.main
}));

const ContinueButton = styled(Button)({
  fontSize: '1.6rem',
  fontFamily: 'DM Sans',
  textAlign: 'center',
  backgroundColor: '#19A664',
  paddingTop: '2rem',
  paddingBottom: '2rem',
  width: '27.5rem',
  borderRadius: 3,
  visibility: 'visible',
  color: 'white'
});

const FinanceInfoSelectForm = ({ financialData, timePeriodType, onSubmit, goBack }) => {
  const { companyAccounts } = useCompanies();

  const [rows, setRows] = useState(initialFinantialTableRows(financialData, companyAccounts));
  const [selectedPeriods, setSelectedPeriods] = useState(
    Object.keys(financialData[Object.keys(financialData)[0]]).filter(e => {
      return (
        e !== 'dump' &&
        (MONTH_NAMES.some(month =>
          String(e)
            .toLowerCase()
            .includes(month)
        ) ||
          QUARTER_NAMES.some(quarter =>
            String(e)
              .toLowerCase()
              .includes(quarter)
          ) ||
          stringIsYear(e))
      );
    })
  );

  const { quickbooksYear } = useSession();
  const [selectedYear, setSelectedYear] = useState(
    quickbooksYear ? new Date(`${quickbooksYear}-1-15`) : null
  );
  const [selectedFiscalStartMonth, setSelectedFiscalStartMonth] = useState(
    quickbooksYear ? new Date('2000-1-15') : null
  );

  const selectedFiscalStartPeriod =
    timePeriodType === 'MONTHLY' && selectedFiscalStartMonth
      ? selectedFiscalStartMonth.getMonth()
      : 0;

  const handleYearChange = date => {
    setSelectedYear(date);
  };

  const handleFiscalStartMonthChange = date => {
    setSelectedFiscalStartMonth(date);
  };

  const doContinue = () => {
    if (!selectedYear && timePeriodType !== 'ANUALLY') {
      dispatchErrorToast('Please select the year your data corresponds to.');
      return;
    }
    if (!quickbooksYear && (timePeriodType === 'MONTHLY' && !selectedFiscalStartMonth)) {
      dispatchErrorToast('Please input the start of your fiscal year.');
      return;
    }
    if (selectedPeriods.length <= 0) {
      dispatchErrorToast('You have not added any months to push.');
      return;
    }
    if (!rows.some(row => row.fileName !== 0)) {
      dispatchErrorToast('You have not defined any metrics for us to read your data around.');
      return;
    }

    const accounts = [];
    const metrifiedRows = rows.filter(row => row.fileName !== 0);
    metrifiedRows.forEach(metrifiedRow => {
      const values = selectedPeriods.map(period => {
        const value =
          typeof metrifiedRow[period] !== 'number'
            ? Number(metrifiedRow[period].replace(/\D\./g, ''))
            : metrifiedRow[period];
        if (!value) return undefined;

        let timePeriod;
        let year;
        switch (timePeriodType) {
          case 'MONTHLY': {
            timePeriod = getTimePeriodFromMonth(period);
            year =
              timePeriod >= selectedFiscalStartMonth.getMonth() + 1
                ? selectedYear.getFullYear()
                : selectedYear.getFullYear() + 1;
            break;
          }
          case 'QUARTERLY': {
            timePeriod = getTimePeriodFromQuarter(period);
            year = selectedYear.getFullYear();
            break;
          }
          case 'ANUALLY': {
            timePeriod = 1;
            year = Number(period);
            break;
          }
          default: {
            timePeriod = 0;
            year = 0;
            break;
          }
        }
        return {
          value,
          period: { timePeriod, timePeriodType, year }
        };
      });
      const account = {
        name: metrifiedRow.valueName,
        metricId: metrifiedRow.fileName,
        values: values.filter(e => e !== undefined)
      };
      accounts.push(account);
    });
    onSubmit(accounts);
  };

  return (
    <Container>
      <TitleAndStepsBox>
        <TitleText>Select your information</TitleText>
        <Step stepNumber="1" stepText="Choose a Year" />
        <Step
          stepNumber="2"
          stepText="Drag and drop the green toggles to choose the time period you want to work with."
        />
        <Step
          stepNumber="3"
          stepText="Choose the rows you want to work with and select a Key metric for each row. "
        />
      </TitleAndStepsBox>
      {!quickbooksYear && (
        <YearMonthPickerBarContainer>
          {timePeriodType !== 'ANUALLY' && (
            <TimeUnitPicker
              variant="year"
              label="Choose Year"
              format="yyyy"
              value={selectedYear}
              onChange={handleYearChange}
            />
          )}
          {timePeriodType === 'MONTHLY' && (
            <TimeUnitPicker
              variant="month"
              label="Financial Year Start"
              format="MMMM"
              value={selectedFiscalStartMonth}
              onChange={handleFiscalStartMonthChange}
            />
          )}
        </YearMonthPickerBarContainer>
      )}
      <TableTitleContainer>
        <TableTitleBox>
          <TableTitle noWrap> {financialData.name} </TableTitle>
        </TableTitleBox>
      </TableTitleContainer>
      <FinancialInfoSelectTable
        data={financialData}
        rows={rows}
        setRows={setRows}
        selectedPeriods={selectedPeriods}
        setSelectedPeriods={setSelectedPeriods}
        selectedFiscalStartPeriod={selectedFiscalStartPeriod}
      />
      <FooterBar>
        <GoBackLink onClick={goBack}>Go back</GoBackLink>
        <ContinueButton variant="contained" type="submit" onClick={doContinue}>
          Continue
        </ContinueButton>
      </FooterBar>
    </Container>
  );
};

FinanceInfoSelectForm.propTypes = {
  financialData: object.isRequired,
  timePeriodType: string.isRequired,
  onSubmit: func.isRequired,
  goBack: func.isRequired
};

export default memo(FinanceInfoSelectForm);
