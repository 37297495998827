import XLSX from 'xlsx';
import { MONTH_NAMES, QUARTER_NAMES } from 'constants/constants';
import { capitalize, stringIsYear } from 'utils/formatUtils';

const sheet2arr = sheet => {
  const result = [];
  let row;
  let rowNum = 0;
  let colNum = 0;
  const range = XLSX.utils.decode_range(sheet['!ref']);
  for (rowNum = range.s.r; rowNum <= range.e.r; rowNum) {
    row = [];
    for (colNum = range.s.c; colNum <= range.e.c; colNum) {
      const nextCell = sheet[XLSX.utils.encode_cell({ r: rowNum, c: colNum })];
      if (typeof nextCell === 'undefined') {
        row.push(undefined);
      } else if (nextCell.w) {
        row.push(nextCell.w);
      } else {
        row.push(nextCell.v);
      }
      colNum += 1;
    }
    result.push(row);
    rowNum += 1;
  }
  return result;
};

export const errorHandler = evt => {
  switch (evt.target.error.code) {
    case evt.target.error.NOT_FOUND_ERR:
      alert('File Not Found!');
      break;
    case evt.target.error.NOT_READABLE_ERR:
      alert('File is not readable');
      break;
    case evt.target.error.ABORT_ERR:
      break;
    default:
      alert('An error occurred reading this file.');
  }
};

// Cleans an array of row values of undefined value filled rows
const cleanArray = array => {
  let i = array.length - 1;
  while (i >= 0) {
    if (array[i].filter(x => x == undefined).length >= 12) {
      array.splice(i, 1);
    }
    i -= 1;
  }
};

export const filterValue = (name, values) => {
  if (name.toLowerCase().includes('total')) return false;
  if (
    Object.keys(values)
      .filter(e => e !== 'dump')
      .every(key => values[key] === '(-)')
  )
    return false;
  return true;
};

export const generateOutputFromWorkbook = (workbook, name) => {
  const sheet = workbook.Sheets[name];
  const arr = sheet2arr(sheet);
  // headerRow variables detects and saves the row corresponding to the table headers
  // Currently, only months are recognised as headers (TODO)

  let timePeriodType;
  const headerRow = arr.findIndex(row => {
    const monthsInRow = row.filter(value =>
      MONTH_NAMES.some(month =>
        String(value)
          .toLowerCase()
          .includes(month)
      )
    );
    const quartersInRow = row.filter(value =>
      QUARTER_NAMES.some(quarter =>
        String(value)
          .toLowerCase()
          .includes(quarter)
      )
    );
    const yearsInRow = row.filter(value => stringIsYear(value));

    if (
      yearsInRow.length >= 2 &&
      yearsInRow.length >= row.filter(value => parseInt(value, 10)).length
    ) {
      timePeriodType = 'ANUALLY';
      return true;
    }
    if (quartersInRow.length > 3) {
      timePeriodType = 'QUARTERLY';
      return true;
    }
    if (monthsInRow.length > 6) {
      timePeriodType = 'MONTHLY';
      return true;
    }
  });

  if (headerRow > 0) {
    const headerArr = arr.slice(headerRow);
    cleanArray(headerArr);
    const transArr = headerArr[0].map((_, colIndex) => headerArr.map(row => row[colIndex]));
    cleanArray(transArr);

    // Use this line instead of next one to transpose the output object's key-values
    // const cleanArr = transArr;
    const cleanArr = transArr[0].map((_, colIndex) => transArr.map(row => row[colIndex]));

    const headers = cleanArr.splice(0, 1)[0];

    const cleanHeaders = headers.map(header => {
      switch (timePeriodType) {
        case 'MONTHLY': {
          const properMonthValue = MONTH_NAMES.slice(12, 24).find(month =>
            String(header)
              .toLowerCase()
              .includes(month)
          );
          return properMonthValue ? capitalize(properMonthValue) : 'dump';
        }
        case 'QUARTERLY': {
          const qString = String(header)
            .toLowerCase()
            .split(/[^a-zA-Z0-9:]/);
          if (['1', 'first', 'q1', '1st'].some(x => qString.includes(x))) return 'Q1';
          if (['2', 'second', 'q2', '2nd'].some(x => qString.includes(x))) return 'Q2';
          if (['3', 'third', 'q3', '3rd'].some(x => qString.includes(x))) return 'Q3';
          if (['4', 'fourth', 'q4', '4th'].some(x => qString.includes(x))) return 'Q4';
          return 'dump';
        }
        case 'ANUALLY': {
          if (stringIsYear(header)) return String(header);
          return 'dump';
        }
        default:
          capitalize(String(header));
      }
    });

    const output = {};
    cleanArr.forEach(it => {
      const item = {};
      it.forEach((value, index) => {
        if (index > 0 && value) {
          item[cleanHeaders[index]] = value.replace(/[^\d.-]/g, '').trim();
        }
      });

      switch (timePeriodType) {
        case 'MONTHLY': {
          MONTH_NAMES.slice(12, 24)
            .map(month => capitalize(month))
            .forEach(month => {
              if (item[month]) {
                if (!parseFloat(item[month].replace(/[^\d.-]/g, ''))) item[month] = '(-)';
              } else {
                item[month] = '(-)';
              }
            });
          break;
        }
        case 'QUARTERLY': {
          QUARTER_NAMES.slice(2, 6)
            .map(quarter => quarter.toUpperCase())
            .forEach(quarter => {
              if (item[quarter]) {
                if (!parseFloat(item[quarter].replace(/[^\d.-]/g, ''))) item[quarter] = '(-)';
              } else {
                item[quarter] = '(-)';
              }
            });
          break;
        }
        case 'ANUALLY': {
          cleanHeaders.forEach(year => {
            if (item[year]) {
              if (!parseFloat(item[year].replace(/[^\d.-]/g, ''))) item[year] = '(-)';
            } else {
              item[year] = '(-)';
            }
          });
          break;
        }
        default:
          break;
      }
      const valueName = it[0] ? it[0].trim() : '?';
      if (filterValue(valueName, item)) output[valueName] = item;
    });
    return output;
  }
  return undefined;
};

export const generateOutputFromQuickbooks = profitAndLossReport => {
  const output = {};
  if (!profitAndLossReport) return output;

  const { columns, rows } = profitAndLossReport;
  if (!columns || !rows) return output;

  rows.forEach(baseRow => {
    baseRow.rows.forEach(rowObject => {
      const value = {};
      Object.keys(rowObject).forEach(key => {
        if (key > 0) {
          if (rowObject[key]) {
            if (rowObject[key].value !== undefined) {
              if (!parseFloat(rowObject[key].value.replace(/[^\d.-]/g, ''))) {
                value[columns[key].month.toUpperCase()] = '(-)';
              } else {
                value[columns[key].month.toUpperCase()] = parseFloat(
                  rowObject[key].value.replace(/[^\d.-]/g, '')
                );
              }
            } else {
              value[columns[key].month.toUpperCase()] = parseFloat(
                rowObject[key].replace(/[^\d.-]/g, '')
              )
                ? parseFloat(rowObject[key].replace(/[^\d.-]/g, ''))
                : '(-)';
            }
          } else {
            value[columns[key].month.toUpperCase()] = '(-)';
          }
        }
      });
      if (
        !Object.values(value)
          .filter(e => e !== 'dump')
          .every(item => item === '(-)')
      ) {
        const key = rowObject[0].value !== undefined ? rowObject[0].value : rowObject[0];
        if (filterValue(key, value)) output[key] = value;
      }
    });
  });
  return output;
};
