import React, { useState, useEffect } from 'react';
import { postProfitAndLoss } from 'state/actions/companyActions';
import FinanceInfoSelectForm from 'components/steps/FinanceInfoSelectForm';
import { useDispatch, useSession, useStatus } from 'hooks';
import { useHistory } from 'react-router-dom';
import { FULFILLED } from 'constants/actionStatusConstants';
import { Box, styled } from '@mui/material';
import SpreadsheetUploader from 'components/steps/SpreadsheetUploaderForm';
import queryString from 'query-string';
import routes from 'constants/routesPaths';
import { bool, func } from 'prop-types';
import { setQuickbooksYear } from 'state/actions/userActions';
import { MONTH_NAMES, QUARTER_NAMES } from 'constants/constants';
import { stringIsYear } from 'utils/formatUtils';
import LoadingQuickbooks from './LoadingQuickbooks';

const Container = styled(Box)(({ justify }) => ({
  alignItems: 'center',
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: justify,
  padding: '5.8rem 6.4rem'
}));

const FinancialsUpload = ({ refreshState, setRefreshState }) => {
  const { step: queryStep, realmId, accessToken } = queryString.parse(window.location.search);

  const history = useHistory();
  const [step, setStep] = useState(
    queryStep === 'quickbooks' || (realmId && accessToken) ? 0.5 : 0
  );
  const [rawFinancialData, setRawFinancialData] = useState();
  const [timePeriodType, setTimePeriodType] = useState();

  const setQuickbooksYearRequest = useDispatch(setQuickbooksYear);
  const postProfitAndLossRequest = useDispatch(postProfitAndLoss);
  const { status: postProfitAndLossStatus } = useStatus(postProfitAndLoss);

  const { companyId } = useSession();

  useEffect(() => {
    if (refreshState) {
      setStep(0);
      setRawFinancialData(null);
      setRefreshState(false);
      setQuickbooksYearRequest(null);
    }
  }, [refreshState]);

  useEffect(() => {
    if (postProfitAndLossStatus === FULFILLED) {
      setQuickbooksYearRequest(null);
      history.push(routes.dashBoard);
      history.go(routes.dashBoard);
    }
  }, [history, postProfitAndLossStatus]);

  const submitRawFinancialData = spreadsheetsData => {
    setRawFinancialData(spreadsheetsData[0]);
    if (
      Object.keys(spreadsheetsData[0][Object.keys(spreadsheetsData[0])[0]]).some(key =>
        MONTH_NAMES.includes(key.toLowerCase())
      )
    )
      setTimePeriodType('MONTHLY');
    if (
      Object.keys(spreadsheetsData[0][Object.keys(spreadsheetsData[0])[0]]).some(key =>
        QUARTER_NAMES.includes(key.toLowerCase())
      )
    )
      setTimePeriodType('QUARTERLY');
    if (
      Object.keys(spreadsheetsData[0][Object.keys(spreadsheetsData[0])[0]]).some(key =>
        stringIsYear(key)
      )
    )
      setTimePeriodType('ANUALLY');
    setStep(step + 1);
  };

  const submitSelectedFinancialData = financialData => {
    postProfitAndLossRequest({ id: companyId, accounts: financialData });
  };

  const goBack = () => {
    setStep(step - 1);
  };

  const quickbooksLoadRawFinancialData = spreadsheetsData => {
    setRawFinancialData(spreadsheetsData);
    if (
      Object.keys(spreadsheetsData[Object.keys(spreadsheetsData)[0]]).some(key =>
        MONTH_NAMES.includes(key.toLowerCase())
      )
    )
      setTimePeriodType('MONTHLY');
    if (
      Object.keys(spreadsheetsData[Object.keys(spreadsheetsData)[0]]).some(key =>
        QUARTER_NAMES.includes(key.toLowerCase())
      )
    )
      setTimePeriodType('QUARTERLY');
    if (
      Object.keys(spreadsheetsData[Object.keys(spreadsheetsData)[0]]).some(key => stringIsYear(key))
    )
      setTimePeriodType('ANUALLY');
    setStep(1);
  };

  return (
    <Container justify={step === 0 ? 'center' : 'flex-start'}>
      {step === 0 && <SpreadsheetUploader onSubmit={submitRawFinancialData} goBack={goBack} />}
      {step === 0.5 && (
        <LoadingQuickbooks
          onLoad={quickbooksLoadRawFinancialData}
          realmId={realmId}
          accessToken={accessToken}
        />
      )}
      {step === 1 && (
        <FinanceInfoSelectForm
          financialData={rawFinancialData}
          timePeriodType={timePeriodType}
          onSubmit={submitSelectedFinancialData}
          goBack={goBack}
        />
      )}
    </Container>
  );
};

FinancialsUpload.propTypes = {
  refreshState: bool.isRequired,
  setRefreshState: func.isRequired
};

export default FinancialsUpload;
