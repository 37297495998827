import React, { useEffect } from 'react';
import { Box, Grid, Typography, styled } from '@mui/material';
import { useCompanies, useDeals, useDispatch } from 'hooks';
import DealItem from 'components/items/DealItem';
import { func } from 'prop-types';
import { getCompanyRecievedDeals, getCompanySharedDeals } from 'state/actions/dealsActions';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
});

const GridContainer = styled(Grid)({
  marginBottom: '4rem',
  width: '100%',
  justifyContent: 'center'
});

const GridItem = styled(Grid)({
  minWidth: '46rem'
});

const Title = styled(Typography)({
  fontSize: '2.4rem',
  fontWeight: 'bold',
  marginBottom: '2rem',
  width: '100%'
});

const DealsList = ({ onDealClick }) => {
  const getCompanySharedDealsRequest = useDispatch(getCompanySharedDeals);
  const getCompanyRecievedDealsRequest = useDispatch(getCompanyRecievedDeals);

  const { userCompanies } = useCompanies();
  const { companySharedDeals, companyReceivedDeals } = useDeals();

  useEffect(() => {
    if (userCompanies.length > 0) {
      getCompanySharedDealsRequest(userCompanies[0].company.id);
    }
  }, [getCompanySharedDealsRequest, userCompanies]);

  useEffect(() => {
    if (userCompanies.length > 0) {
      getCompanyRecievedDealsRequest(userCompanies[0].company.id);
    }
  }, [getCompanyRecievedDealsRequest, userCompanies]);

  return (
    <Container>
      {companySharedDeals.length > 0 && <Title> Shared Deals </Title>}
      <GridContainer
        container
        spacing={{ xs: 2, md: 2 }}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        columns={{ xs: 1, sm: 2, md: 3 }}
      >
        {companySharedDeals.map((item, index) => (
          <GridItem item xs={1} sm={1} md={1} key={`shared-deal-${index}`}>
            <DealItem deal={item} type="shared" onClick={() => onDealClick(item, 'shared')} />
          </GridItem>
        ))}
      </GridContainer>
      {companyReceivedDeals.length > 0 && <Title> Received Deals </Title>}
      <GridContainer
        container
        spacing={{ xs: 2, md: 2 }}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        columns={{ xs: 1, sm: 2, md: 3 }}
      >
        {companyReceivedDeals.map((item, index) => (
          <GridItem item xs={1} sm={1} md={1} key={`recieved-deal-${index}`}>
            <DealItem deal={item} type="received" onClick={() => onDealClick(item, 'received')} />
          </GridItem>
        ))}
      </GridContainer>
    </Container>
  );
};

DealsList.propTypes = {
  onDealClick: func.isRequired
};

export default DealsList;
