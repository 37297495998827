import React from 'react';
import { Line } from 'react-chartjs-2';
import { styled, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MONTH_NAMES, QUARTER_NAMES } from 'constants/constants';
import { capitalize, dashboardToYearArray, stringIsYear } from 'utils/formatUtils';
import { string, array } from 'prop-types';

const Container = styled(Box)({
  backgroundColor: '#FFFFFF',
  position: 'relative',
  minWidth: '48rem',
  maxWidth: '48rem',
  padding: '2.6rem',
  marginRight: '4.2rem',
  marginTop: '4.2rem'
});

const Title = styled(Typography)({
  lineHeight: '140%',
  fontWeight: 700,
  paddingBottom: '3.33vw'
});

const LineChart = ({ title, dataPoints, groupBy }) => {
  const theme = useTheme();
  let labels;
  switch (groupBy) {
    case 'MONTHLY':{
      labels = MONTH_NAMES.slice(0, 12).map(month => capitalize(month));
      break;
    };
    case 'QUARTERLY':{
      labels = QUARTER_NAMES.slice(2, 6).map(quarter => capitalize(quarter));
      break;
    };
    case 'ANUALLY':{
      labels = dataPoints.filter(e => stringIsYear(e.year)).map(yearValue => yearValue.year).sort().map(e => String(e));
      break;
    };
    default: break;
  }

  const getData = (theme, dataPoints) => {
    const yearValues = dashboardToYearArray(dataPoints, groupBy);
    const datasets = yearValues.map((yearData, index) => {
      let data;
      switch (groupBy) {
        case 'MONTHLY':{
          data = [...Array(13).keys()].splice(1).map(month => {
            if (!yearData[month]) return null;
            return yearData[month];
          });
          break;
        };
        case 'QUARTERLY':{
          data = [...Array(5).keys()].splice(1).map(month => {
            if (!yearData[month]) return null;
            return yearData[month];
          });
          break;
        };
        case 'ANUALLY':{
          data = Object.keys(yearData).map(month => {
            if (!yearData[month]) return null;
            return yearData[month];
          });
          break;
        };
        default: break;
      };
      const colorIndex = (index % 12) + 1;

      return {
        label: yearData.year || 'Annual Report',
        data,
        backgroundColor: theme.palette.rainbow[`color${colorIndex}`].lighter,
        borderColor: theme.palette.rainbow[`color${colorIndex}`].main,
        borderWidth: 3,
        fill: true
      };
    });

    const data = {
      labels,
      datasets
    };
    
    return data;
  };

  const getOptions = () => {
    const options = {
      scales: {
        y: {
          grid: {
            color: 'rgba(0, 0, 0, 0)'
          },
          ticks: {
            display: false
          }
        },
        x: {
          grid: {
            color: 'rgba(0, 0, 0, 0)'
          },
          ticks: {
            display: false
          }
        }
      },
      interaction: {
        axis: 'xy',
        intersect: false,
        mode: 'nearest'
      },
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            usePointStyle: true,
            pointStyle: 'circle'
          }
        }
      },
      elements: {
        point: {
          pointRadius: 2
        }
      }
    };
    return options;
  };

  return (
    <Container container>
      <Title variant="h2">{title}</Title>
      <Line data={getData(theme, dataPoints)} options={getOptions()} />
    </Container>
  );
};

LineChart.propTypes = {
  title: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  dataPoints: array.isRequired,
  groupBy: string.isRequired
};

export default LineChart;
