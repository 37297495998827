import React from 'react';
import { Box, Typography, Card, Divider, styled } from '@mui/material';
import DealsOptionsPicker from 'components/deals/DealsOptionsPicker';
import { useCompanies, useIndicators } from 'hooks';
import { func, object, string } from 'prop-types';

const Container = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'left',
  alignItems: 'start',
  width: '45rem',
  padding: '1.6rem 1.8rem 2rem 2.8rem',
  borderRadius: 4,
  marginBottom: '1rem',
  cursor: 'pointer'
});

const TopCornersBox = styled(Box)({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
});

const ActiveDealText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '1.4rem',
  lineHeight: '2.8rem',
  fontWeight: '500'
}));

const CanceledDealText = styled(Typography)({
  color: '#EB5757',
  fontSize: '1.4rem',
  lineHeight: '2.8rem',
  fontWeight: '500'
});

const DealTitleText = styled(Typography)({
  color: 'black',
  fontSize: '2.4rem',
  fontWeight: '500'
});

const ItemDivider = styled(Divider)({
  marginTop: '2rem',
  marginBottom: '1.5rem',
  width: '100%'
});

const DealLabelText = styled(Typography)({
  color: '#979797',
  fontSize: '1.4rem'
});

const DealContentText = styled(Typography)({
  color: 'black',
  fontSize: '1.4rem',
  minHeight: '4.2rem',
  maxLines: 2
});

const DealExpirationText = styled(Typography)({
  color: '#979797',
  fontSize: '1.4rem',
  width: '100%',
  textAlign: 'right'
});

const DealItem = ({
  deal: { active, id, indicators, expiration, sharingCompanyId, receivingCompanyId },
  onClick,
  type
}) => {
  const expirationDate = new Date(expiration);

  const { companies } = useCompanies();
  const { indicators: allIndicators } = useIndicators();

  let sharedInfo = '';
  indicators.forEach((indi, index) => {
    if (index !== 0) {
      sharedInfo += ', ';
    }
    sharedInfo += allIndicators.find(indis => indis.id === indi.indicatorId).name;
  });

  const findCompany = type => {
    return companies.find(company => company.id === type).name;
  };

  return (
    <Container onClick={onClick}>
      <TopCornersBox>
        {active ? (
          <ActiveDealText> Active Deal </ActiveDealText>
        ) : (
          <CanceledDealText> Inactive Deal </CanceledDealText>
        )}
        <DealsOptionsPicker dealId={id} />
      </TopCornersBox>
      <DealTitleText>
        {findCompany(type === 'shared' ? receivingCompanyId : sharingCompanyId)}
      </DealTitleText>
      <ItemDivider />
      <DealLabelText>Shared Information</DealLabelText>
      <DealContentText>{sharedInfo}</DealContentText>
      <ItemDivider />
      <DealExpirationText>
        {`Deal expires on ${expirationDate.getMonth() + 1}/${expirationDate.getDate() +
          1}/${expirationDate.getFullYear()}`}
      </DealExpirationText>
    </Container>
  );
};

DealItem.propTypes = {
  deal: object.isRequired,
  onClick: func.isRequired,
  type: string.isRequired
};

export default DealItem;
