import { capitalize, stringIsYear } from 'utils/formatUtils';
import { MONTH_NAMES, QUARTER_NAMES } from 'constants/constants';

export const initialFinantialTableRows = (data, accounts) => {
  const initialRows = [];
  let i = 0;
  Object.keys(data).forEach(key => {
    let itemFileName = 0;
    if (accounts.find(account => account.name === key)) {
      itemFileName = accounts.find(account => account.name === key).metricId;
    }
    const row = {
      id: i,
      fileName: itemFileName,
      color: null,
      valueName: key
    };
    Object.keys(data[key]).forEach(dataKey => {
      row[dataKey] = data[key][dataKey];
    });
    if (row.valueName !== 'name') initialRows.push(row);
    i += 1;
  });
  return initialRows;
};

export const getFinantialTableColumns = (
  data,
  financialMetricSelect,
  selectMetricEditInputCell,
  fiscalMonthStart,
  headerRenderer,
  valueNameCellRenderer,
  cellRenderer
) => {
  const totalWidth = 73 * 12;
  const columns = [
    {
      field: 'fileName',
      headerName: data.name,
      width: 192,
      editable: true,
      sortable: false,
      align: 'center',
      renderCell: financialMetricSelect,
      renderEditCell: selectMetricEditInputCell
    },
    {
      field: 'valueName',
      headerName: '',
      width: 162,
      sortable: false,
      align: 'left',
      renderCell: valueNameCellRenderer
    }
  ];
  // if filter return true object stays, if false, object goes
  const columnIndexes = Object.keys(data[Object.keys(data)[0]]).filter(e => {
    return (
      e !== 'dump' &&
      (MONTH_NAMES.some(month =>
        String(e)
          .toLowerCase()
          .includes(month)
      ) ||
        QUARTER_NAMES.some(quarter =>
          String(e)
            .toLowerCase()
            .includes(quarter)
        ) ||
        stringIsYear(e))
    );
  });

  const columnWidth = Number(totalWidth / columnIndexes.length);
  const timePeriodColumns = [];
  columnIndexes.forEach(index => {
    timePeriodColumns.push({
      field: index,
      headerName: capitalize(index),
      width: columnWidth,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderHeader: headerRenderer,
      renderCell: cellRenderer
    });
  });

  columns.push(...timePeriodColumns.slice(fiscalMonthStart, 12));
  columns.push(...timePeriodColumns.slice(0, fiscalMonthStart));

  return columns;
};

export const getTimePeriodFromMonth = month => {
  switch (month.toLowerCase()) {
    case 'jan':
      return 1;
    case 'feb':
      return 2;
    case 'mar':
      return 3;
    case 'apr':
      return 4;
    case 'may':
      return 5;
    case 'jun':
      return 6;
    case 'jul':
      return 7;
    case 'aug':
      return 8;
    case 'sep':
      return 9;
    case 'oct':
      return 10;
    case 'nov':
      return 11;
    case 'dec':
      return 12;
    default:
      return 0;
  }
};

export const getTimePeriodFromQuarter = quarter => {
  switch (quarter.toLowerCase()) {
    case 'q1':
      return 1;
    case 'q2':
      return 2;
    case 'q3':
      return 3;
    case 'q4':
      return 4;
    default:
      return 0;
  }
};
