import React, { memo } from 'react';
import { func } from 'prop-types';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import { REJECTED } from 'constants/actionStatusConstants';
import Input from 'components/common/Input';
import { resetPassword as resetPasswordValidations } from 'utils/constraints';
import { useStatus, useForm, useValidation, useTextInputProps } from 'hooks';
import { resetPassword } from 'state/actions/userActions';

import { Box, Card, Button, Typography, styled } from '@mui/material';

const messages = defineMessages({
  password: { id: 'reset.form.password' },
  confirmPassword: { id: 'reset.form.confirmPassword' }
});

const fields = {
  password: 'password',
  confirmPassword: 'confirmPassword'
};

const Container = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  height: 'calc(100% - 9rem)'
});

const CardSignIn = styled(Card)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: '4.3rem 3.3rem 3.2rem 3.3rem',
  width: '55rem'
});

const ResetPasswordTitle = styled(Typography)({
  marginBottom: '0.6rem'
});

const ErrorMessage = styled(Typography)({
  fontSize: '1.6rem',
  color: '#EB5757',
  paddingBottom: '0.6rem'
});

const FormContainer = styled(Box)({
  width: '100%'
});

export const ResetPasswordForm = ({ onSubmit, token }) => {
  const intl = useIntl();
  const { status, error } = useStatus(resetPassword);
  const validator = useValidation(resetPasswordValidations);
  const {
    values,
    errors,
    handleValueChange,
    handleSubmit,
    handleFocus,
    handleBlur,
    activeFields,
    touched
  } = useForm(
    {
      onSubmit,
      validator,
      validateOnBlur: true,
      validateOnChange: true,
      initialValues: { token }
    },
    [onSubmit]
  );

  const inputProps = useTextInputProps(
    handleValueChange,
    handleFocus,
    handleBlur,
    values,
    errors,
    activeFields,
    touched
  );

  return (
    <Container>
      <CardSignIn>
        <ResetPasswordTitle variant="h1">
          <FormattedMessage id="reset.title" />
        </ResetPasswordTitle>

        <FormContainer component="form" fullWidth onSubmit={handleSubmit}>
          {status === REJECTED && (
            <ErrorMessage>{error || <FormattedMessage id="reset.error" />}</ErrorMessage>
          )}
          <Input
            name="Password"
            type="password"
            label={intl.formatMessage(messages.password)}
            {...inputProps(fields.password)}
          />
          <Input
            name="Confirm Password"
            type="password"
            label={intl.formatMessage(messages.confirmPassword)}
            {...inputProps(fields.confirmPassword)}
          />
          <Button variant="contained" fullWidth type="submit" sx={{ mt: '1.25vw' }}>
            <FormattedMessage id="reset.form.submit" />
          </Button>
        </FormContainer>
      </CardSignIn>
    </Container>
  );
};

ResetPasswordForm.propTypes = {
  onSubmit: func.isRequired
};

export default memo(ResetPasswordForm);
