import React, { useEffect, useMemo } from 'react';
import { Box, Typography, Card, Divider, Link, Avatar, styled } from '@mui/material';
import DealsOptionsPicker from 'components/deals/DealsOptionsPicker';
import { useDispatch, useStatus, useCompanies, useDashboard, useIndicators } from 'hooks';
import { getCompany, getIndustry, getUser } from 'state/actions/companyActions';
import { FULFILLED } from 'constants/actionStatusConstants';
import { object } from 'prop-types';
import { addHttpIfMissing } from 'utils/formatUtils';
import { getDealDashboard } from 'state/actions/dashboardActions';
import { actions } from 'state/reducers/dashboardReducer';
import Charts from 'components/dashboard/Charts';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
});

const SharedWithLabel = styled(Typography)({
  fontSize: '1.8rem',
  marginBottom: '2.4rem'
});

const SharedWithBarContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
});

const SharedWithElementsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '4rem'
});

const CompanyInitialsAvatar = styled(Avatar)({
  backgroundColor: '#9BC9FF',
  borderRadius: '50%',
  textAlign: 'center',
  color: 'white',
  fontWeight: 'bold',
  fontSize: '2.4rem',
  marginRight: '1.6rem',
  width: '3.8rem',
  height: '3.8rem',
  lineHeight: '3.8rem'
});

const DealActiveIndicator = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '1.4rem',
  padding: '0.5rem 1rem',
  borderColor: theme.palette.primary.main,
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: '2rem',
  marginRight: '2.6rem'
}));

const DealCard = styled(Card)({
  padding: '2.7rem 3.1rem 0 3.1rem',
  width: '100%',
  flexDirection: 'column',
  marginBottom: '6.2rem'
});

const CompanyAvatar = styled(Avatar)(({ theme }) => ({
  width: '10rem',
  height: '10rem',
  backgroundColor: theme.palette.info.main
}));

const CompanyTextHolderBox = styled(Box)({
  display: 'flex',
  marginLeft: '2.9rem',
  flexDirection: 'column'
});

const DealCardTitle = styled(Typography)({
  fontSize: '3.6rem',
  fontWeight: '500'
});

const DealCardLabel = styled(Typography)(({ margin }) => ({
  fontSize: '1.6rem',
  fontWeight: '700',
  marginBottom: margin ? '1.6rem' : 0
}));

const DealCardText = styled(Typography)(({ margin }) => ({
  fontSize: '1.6rem',
  fontWeight: '400',
  marginBottom: margin ? '3.3rem' : 0,
  maxWidth: '71.2rem'
}));

const DealCardLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '1.6rem',
  fontWeight: '400',
  fontFamily: 'DM Sans'
}));

const ItemHorizontalDivider = styled(Divider)({
  marginTop: '2rem',
  marginBottom: '1.5rem',
  width: '100%'
});

const DealsCardFooter = styled(Box)({
  height: '17.6rem',
  padding: '3.9rem 0 3.9rem 0',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center'
});

const DealsCardFooterElement = styled(Box)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  maxWidth: '21rem'
});

const VerticalDivider = styled(Divider)({
  height: '100%'
});

const Title = styled(Typography)({
  fontSize: '2.4rem',
  fontWeight: 'bold',
  width: '100%'
});

const DealDetails = ({
  deal: {
    active,
    id,
    indicators,
    receivingCompanyId,
    receivingCompanyUserId,
    sharingCompanyId,
    description,
    type
  }
}) => {
  const getUserRequest = useDispatch(getUser);
  const getIndustryRequest = useDispatch(getIndustry);
  const getDealDashboardRequest = useDispatch(getDealDashboard);
  const cleanDeals = useDispatch(actions.cleanDeals);
  const companyRequest = useDispatch(getCompany);

  const { status: getUserStatus } = useStatus(getUser);
  const { status: getIndustryStatus } = useStatus(getIndustry);

  const { companies, industry, user } = useCompanies();
  const { dealDashboard } = useDashboard();
  const { indicators: allIndicators } = useIndicators();

  const receivingCompany = useMemo(
    () => companies.find(company => company.id === receivingCompanyId),
    [companies, receivingCompanyId]
  );

  const sharingCompany = useMemo(() => companies.find(company => company.id === sharingCompanyId), [
    companies,
    sharingCompanyId
  ]);

  const companyType = type === 'shared' ? receivingCompany : sharingCompany;

  useEffect(() => {
    indicators.forEach(indi => {
      getDealDashboardRequest({
        dealId: id,
        dashboardParams: {
          indicatorId: indi.indicatorId,
          startPeriod: {
            timePeriod: 1,
            timePeriodType: 'MONTHLY',
            year: 0
          },
          endPeriod: {
            timePeriod: 12,
            timePeriodType: 'MONTHLY',
            year: 2021
          },
          groupBy: 'MONTHLY',
          timePeriodType: 'MONTHLY'
        },
        indicatorName: allIndicators.find(indicator => indi.indicatorId === indicator.id).name
      });
    });
    return () => {
      cleanDeals();
    };
  }, [allIndicators, cleanDeals, getDealDashboardRequest, id, indicators]);

  useEffect(() => {
    getUserRequest(receivingCompanyUserId);
  }, [getUserRequest, receivingCompanyId, receivingCompanyUserId]);

  useEffect(() => {
    companyRequest(sharingCompanyId);
  }, [companyRequest, sharingCompanyId]);

  useEffect(() => {
    getIndustryRequest(companyType.industries[0].industryId);
  }, [getIndustryRequest, companyType.industries]);

  return (
    <Container>
      <SharedWithLabel>Shared {type === 'shared' ? 'with' : 'by'}</SharedWithLabel>
      <SharedWithBarContainer>
        <SharedWithElementsBox>
          <CompanyInitialsAvatar>{companyType.name.split('')[0]}</CompanyInitialsAvatar>
          {getUserStatus === FULFILLED && (
            <Title>
              {user.firstName} {user.lastName}, {companyType.name}
            </Title>
          )}
        </SharedWithElementsBox>
        <SharedWithElementsBox>
          {active && <DealActiveIndicator>Active Deal</DealActiveIndicator>}
          <DealsOptionsPicker dealId={id} />
        </SharedWithElementsBox>
      </SharedWithBarContainer>
      <DealCard>
        <SharedWithElementsBox>
          <CompanyAvatar />
          <CompanyTextHolderBox>
            <DealCardLabel>Organization</DealCardLabel>
            <DealCardTitle>{companyType.name}</DealCardTitle>
          </CompanyTextHolderBox>
        </SharedWithElementsBox>
        {description && (
          <>
            <DealCardLabel margin="true">About</DealCardLabel>
            <DealCardText margin="true">{description}</DealCardText>
          </>
        )}
        <ItemHorizontalDivider />
        <DealsCardFooter>
          {getIndustryStatus === FULFILLED && (
            <>
              <DealsCardFooterElement>
                <DealCardLabel>Main Industry</DealCardLabel>
                <DealCardText>{industry.name}</DealCardText>
              </DealsCardFooterElement>
              <VerticalDivider orientation="vertical" />
            </>
          )}
          <DealsCardFooterElement>
            <DealCardLabel>Company size</DealCardLabel>
            <DealCardText>{companyType.size} Employees</DealCardText>
          </DealsCardFooterElement>
          <VerticalDivider orientation="vertical" />
          {companyType.website && (
            <DealsCardFooterElement>
              <DealCardLabel>Website</DealCardLabel>
              <DealCardLink href={addHttpIfMissing(companyType.website)} underline="hover">
                {companyType.website}
              </DealCardLink>
            </DealsCardFooterElement>
          )}
        </DealsCardFooter>
      </DealCard>
      <Title>Shared Information</Title>
      <Charts chartsData={dealDashboard} />
    </Container>
  );
};

DealDetails.propTypes = {
  deal: object.isRequired
};

export default DealDetails;
