import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { arrayOf, number, string } from 'prop-types';

const Container = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center'
});

const StepContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex'
});

const StepCircle = styled(Box)(({ theme, active }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette[active ? 'primary' : 'black012'].main,
  borderRadius: '50%',
  color: theme.palette[active ? 'white' : 'black026'].main,
  display: 'flex',
  justifyContent: 'center',
  height: '2.4rem',
  minWidth: '2.4rem'
}));

const StepName = styled(Typography)(({ theme, active, fontSize }) => ({
  color: theme.palette[active ? 'black087' : 'black038'].main,
  marginLeft: '0.8rem',
  fontSize: fontSize || '1.6rem',
  maxWidth: '28rem'
}));

const Separator = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.black012.main,
  height: '0.1rem',
  marginLeft: '2.4rem',
  marginRight: '2.4rem',
  width: '0.7rem'
}));

const StepsIndicator = ({ steps, showStepsUpTo, currentStep, fontSize }) => {
  let subPagesReduced = steps;
  if (showStepsUpTo) {
    if (currentStep <= showStepsUpTo - 1) {
      subPagesReduced = steps.slice(0, showStepsUpTo);
    }
  }

  return (
    <Container>
      {subPagesReduced.map((stepName, index) => {
        const isStepActive = currentStep >= 0 ? index <= currentStep : true;
        return Number.isInteger(currentStep) ? (
          <StepContainer key={`${stepName}-${index}`}>
            <StepCircle active={isStepActive}>
              <Typography>{index + 1}</Typography>
            </StepCircle>
            <StepName active={isStepActive} fontSize={fontSize}>
              {stepName}
            </StepName>
            {index < subPagesReduced.length - 1 && <Separator />}
          </StepContainer>
        ) : null;
      })}
    </Container>
  );
};

StepsIndicator.propTypes = {
  steps: arrayOf(string).isRequired,
  showStepsUpTo: number,
  currentStep: number,
  fontSize: string
};

export default StepsIndicator;
