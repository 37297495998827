import React, { useEffect } from 'react';
import { TextField, styled } from '@mui/material';
import { arrayOf, bool, func, string } from 'prop-types';
import { useIntl } from 'react-intl';

import { parseInputErrors } from 'utils/helpers';

const CustomInput = styled(TextField)({
  fontSize: '1.6rem',
  marginBottom: '1.7rem'
});

const Input = ({ label, name, value, onChange, errors, active, touched, ...props }) => {
  const intl = useIntl();

  // Register field in the form
  useEffect(() => {
    onChange({ target: { value } }, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomInput
      id="outlined-basic"
      label={name}
      variant="outlined"
      size="large"
      name={name}
      value={value}
      onChange={onChange}
      fullWidth
      error={errors ? errors[0] : null}
      helperText={touched && errors ? intl.formatMessage({ id: parseInputErrors(errors) }) : null}
      {...props}
    />
  );
};

Input.propTypes = {
  name: string.isRequired,
  label: string,
  value: string,
  onChange: func.isRequired,
  errors: arrayOf(string),
  active: bool.isRequired,
  touched: bool.isRequired
};

export default Input;
