import React, { createContext, useState } from 'react';
import { node } from 'prop-types';

export const RegisterUserContext = createContext({
  step: 0,
  setStep: () => {},
  email: '',
  setEmail: () => {},
  company: '',
  setCompany: () => {},
  rawFinancialData: {},
  setRawFinancialData: () => {}
});

export const RegisterUserProvider = ({ startingPage, children }) => {
  const [step, setStep] = useState(startingPage);
  const [email, setEmail] = useState('');
  const [rawFinancialData, setRawFinancialData] = useState({});
  const [company, setCompany] = useState({
    companyID: null,
    name: '',
    website: '',
    size: '',
    mainIndustryId: null,
    role: ''
  });

  const value = {
    step,
    setStep,
    email,
    setEmail,
    company,
    setCompany,
    rawFinancialData,
    setRawFinancialData
  };

  return <RegisterUserContext.Provider value={value}>{children}</RegisterUserContext.Provider>;
};

RegisterUserProvider.propTypes = {
  startingPage: node.isRequired,
  children: node.isRequired
};
