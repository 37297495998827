import validate from 'validate.js';

const regexLetters = /^[a-zA-Z\s]*$/;
const regexPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/; // symbols (?=.*[!@#$%^&*])

export const login = {
  email: {
    presence: { message: 'email.presence' },
    email: { message: 'email.invalid' }
  },
  password: {
    presence: { message: 'password.presence' },
    format: {
      pattern: regexPassword,
      message: 'password.invalid'
    }
  }
};

export const signUp = {
  firstName: {
    presence: { message: 'firstName.presence' },
    length: {
      minimum: 2,
      maximum: 20,
      message: 'field.length'
    },
    format: {
      pattern: regexLetters,
      message: 'field.characters'
    }
  },
  lastName: {
    presence: { message: 'lastName.presence' },
    length: {
      minimum: 2,
      maximum: 20,
      message: 'field.length'
    },
    format: {
      pattern: regexLetters,
      message: 'field.characters'
    }
  },
  email: {
    presence: { message: 'email.presence' },
    email: { message: 'email.invalid' }
  },
  password: {
    presence: { message: 'password.presence' },
    format: {
      pattern: regexPassword,
      message: 'password.invalid'
    }
  }
};

export const recoverPassword = {
  email: {
    presence: { message: 'email.presence' },
    email: { message: 'email.invalid' }
  }
};

export const resetPassword = {
  password: {
    presence: { message: 'password.presence' },
    format: {
      pattern: regexPassword,
      message: 'password.invalid'
    }
  },
  confirmPassword: {
    presence: { message: 'confirmPassword.presence' },
    equality: {
      attribute: 'password',
      message: 'confirmPassword.equality'
    }
  }
};

validate.validators.presence.options = { allowEmpty: false };

export const validations = (constraints, props = {}) => data =>
  validate(data, constraints, props) || {};
