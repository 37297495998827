import React, { useState, useEffect } from 'react';
import { Box, Stack, Typography, styled } from '@mui/material';
import IndicatorTable from 'components/dashboard/IndicatorTable';
import { useDashboard, useIndicators } from 'hooks';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
});

const IndicatorItem = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
});

const Title = styled(Typography)({
  fontSize: '2.4rem',
  fontWeight: 'bold',
  marginBottom: '2rem',
  width: '100%'
});

const IndicatorTitleBar = styled(Stack)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '2.2rem 0 2.2rem 0'
});

const TableIndicators = () => {
  const [groupedDashboard, setGroupedDashboard] = useState({});
  const { indicators } = useIndicators();
  const { companyDashboard } = useDashboard();

  useEffect(() => {
    const newGroup = {};
    Object.keys(companyDashboard).forEach(element => {
      if (companyDashboard[element].groupBy === companyDashboard[element].timePeriodType) {
        if (newGroup[companyDashboard[element].indicatorId]) {
          newGroup[companyDashboard[element].indicatorId] = [
            ...newGroup[companyDashboard[element].indicatorId],
            companyDashboard[element]
          ];
        } else {
          newGroup[companyDashboard[element].indicatorId] = [companyDashboard[element]];
        }
      }
    });
    setGroupedDashboard(newGroup);
  }, []);

  return (
    <Container>
      {Object.keys(groupedDashboard).map((key, index) => (
        <IndicatorItem key={`indicator-item-${index}`}>
          <IndicatorTitleBar>
            <Title>
              {
                indicators.find(indicator => indicator.id === groupedDashboard[key][0].indicatorId)
                  .name
              }
            </Title>
          </IndicatorTitleBar>
          {groupedDashboard[key].map(({ timePeriodType, data }, groupIndex) => (
            <IndicatorTable
              key={`table-item-${groupIndex}`}
              data={data}
              timePeriodType={timePeriodType}
            />
          ))}
        </IndicatorItem>
      ))}
    </Container>
  );
};

export default TableIndicators;
