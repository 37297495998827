import React from 'react';
import { Box, Typography, Button, styled } from '@mui/material';
import { func } from 'prop-types';

const Container = styled(Box)({
  width: '100%',
  marginTop: '5rem',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column'
});

const NoDashboardTextCentralBox = styled(Box)({
  width: '100%',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
});

const NoDashboardText = styled(Typography)({
  marginTop: '3.2rem',
  marginBottom: '3.2rem',
  fontSize: '2.4rem',
  maxWidth: '40rem',
  textAlign: 'center'
});

const UploadFinancialButton = styled(Button)(({ theme }) => ({
  color: 'white',
  backgroundColor: theme.palette.primary.main,
  height: '2.5vw',
  paddingLeft: '1.4rem',
  paddingRight: '1.4rem'
}));

const NoCharts = ({ onButtonPress }) => {
  return (
    <Container>
      <NoDashboardTextCentralBox>
        <Typography variant="h1"> There is no company data yet </Typography>
        <NoDashboardText>
          Please upload your company financial data within last two years so we visualize and work
          with it.
        </NoDashboardText>
        <UploadFinancialButton variant="contained" onClick={onButtonPress}>
          UPLOAD FINANCIAL DATA
        </UploadFinancialButton>
      </NoDashboardTextCentralBox>
    </Container>
  );
};

NoCharts.propTypes = {
  onButtonPress: func.isRequired
};

export default NoCharts;
