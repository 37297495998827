import React, { useState } from 'react';
import {
  Button,
  styled,
  Dialog,
  Divider,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from '@mui/material';
import TimeUnitPicker from 'components/common/TimeUnitPicker';
import CloseIcon from '@mui/icons-material/Close';
import { func, bool } from 'prop-types';

const YearPickerDialogTitle = styled(DialogTitle)({
  fontSize: '3.2rem',
  fontFamily: 'DM Serif Display',
  fontWeight: 'bold',
  textAlign: 'center',
  padding: '3.9rem 3.3rem 2.3rem 3.3rem'
});

const YearPickerIconButton = styled(IconButton)({
  position: 'absolute',
  right: 8,
  top: 8,
  color: 'black'
});

const YearPickerDialogContent = styled(DialogContent)({
  padding: '0 3.3rem 0 3.3rem'
});

const YearPickerDialogContentText = styled(DialogContentText)({
  fontSize: '1.8rem',
  fontFamily: 'DM Sans',
  marginRight: '4.8rem',
  color: 'black',
  justifyContent: 'center'
});

const YearPickerDialogDivider = styled(Divider)({
  marginTop: '2.6rem',
  marginBottom: '2.6rem'
});

const YearPickerDialogActions = styled(DialogActions)({
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  padding: '3.7rem 3.3rem'
});

const ContinueButton = styled(Button)({
  fontSize: '1.6rem',
  height: '6.5rem',
  marginTop: '1rem',
  paddingTop: '2rem',
  paddingBottom: '2rem',
  width: '48.4rem'
});

const YearPickerDialog = ({ startDealDialogOpen, handleClose, onSubmit }) => {
  const [selectedYear, setSelectedYear] = useState(null);

  const handleContinue = () => {
    if (selectedYear) {
      onSubmit(selectedYear.getFullYear());
    } else {
      alert(
        "Please pick the fiscal year you want to pull from your quickbook's company data from."
      );
    }
  };

  return (
    <Dialog open={startDealDialogOpen} onClose={handleClose}>
      <YearPickerDialogTitle>
        <>Import from QuickBooks</>
        <YearPickerIconButton aria-label="close" color="black" onClick={handleClose}>
          <CloseIcon />
        </YearPickerIconButton>
      </YearPickerDialogTitle>
      <YearPickerDialogContent>
        <YearPickerDialogContentText>Select fiscal year</YearPickerDialogContentText>
        <YearPickerDialogDivider />
        <TimeUnitPicker
          variant="year"
          label="Choose Year"
          format="yyyy"
          value={selectedYear}
          onChange={setSelectedYear}
        />
      </YearPickerDialogContent>
      <YearPickerDialogActions>
        <ContinueButton variant="contained" onClick={handleContinue}>
          Continue
        </ContinueButton>
      </YearPickerDialogActions>
    </Dialog>
  );
};

YearPickerDialog.propTypes = {
  startDealDialogOpen: bool.isRequired,
  handleClose: func.isRequired,
  onSubmit: func.isRequired
};

export default YearPickerDialog;
