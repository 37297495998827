import React, { memo } from 'react';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';

import { useDispatch, useStatus } from 'hooks';
import ResetPasswordForm from 'components/user/ResetPasswordForm';
import { resetPassword } from 'state/actions/userActions';
import routes from 'constants/routesPaths';
import { FULFILLED } from 'constants/actionStatusConstants';

const ResetPasswordPage = () => {
  const { token } = queryString.parse(window.location.search);
  const resetPasswordRequest = useDispatch(resetPassword);
  const { status: resetpasswordStatus } = useStatus(resetPassword);

  if (resetpasswordStatus === FULFILLED) {
    return <Redirect to={{ pathname: routes.login }} />;
  }

  return <ResetPasswordForm onSubmit={resetPasswordRequest} token={token} />;
};

export default memo(ResetPasswordPage);
