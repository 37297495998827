import React from 'react';
import { styled, Box } from '@mui/material';
import { INDICATOR_NAMES } from 'constants/constants';
import { any, arrayOf } from 'prop-types';
import LineChart from './charts/LineChart';
import BarChart from './charts/BarChart';

const Container = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  height: 'auto',
  paddingBottom: '5rem'
});

const ChartsBox = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  width: '100%',
  height: 'auto',
  justifyContent: 'space-evenly'
});

const Charts = ({ chartsData }) => {
  const lastTwoYears = [new Date().getFullYear() - 1, new Date().getFullYear()];
  const lastYear = [new Date().getFullYear()];

  const getChartData = (name, yearsFilter) => {
    let indicator = chartsData.find(x => x.indicatorName === name && x.timePeriodType === 'MONTHLY');
    if (!indicator) {
      indicator = chartsData.find(x => x.indicatorName === name && x.timePeriodType === 'QUARTERLY');
      if (!indicator) {
        indicator = chartsData.find(x => x.indicatorName === name && x.timePeriodType === 'ANUALLY');
        if (!indicator) return undefined;
        return {
          ...indicator,
          data: indicator.data.filter(data => yearsFilter.includes(data.year))
        };
      }
      return {
        ...indicator,
        data: indicator.data.filter(data => yearsFilter.includes(data.year))
      };
    }
    return {
      ...indicator,
      data: indicator.data.filter(data => yearsFilter.includes(data.year))
    };
  };

  const operatingExpensesIndicator = getChartData(
    INDICATOR_NAMES.operatingExpenses,
    lastTwoYears
  );
  const cogsIndicator = getChartData(INDICATOR_NAMES.cogs, lastTwoYears);
  const grossRevenueIndicator = getChartData(INDICATOR_NAMES.grossRevenue, lastTwoYears);
  const itdaIndicator = getChartData(INDICATOR_NAMES.itda, lastYear);
  const ebitdaIndicator = getChartData(INDICATOR_NAMES.ebitda, lastTwoYears);
  const netRevenueIndicator = getChartData(INDICATOR_NAMES.netRevenue, lastTwoYears);
  const netIncomeIndicator = getChartData(INDICATOR_NAMES.netIncome, lastTwoYears);

  return (
    <Container container>
      <ChartsBox>
        {!!operatingExpensesIndicator?.data.length && (
          <LineChart
            title={operatingExpensesIndicator.indicatorName}
            dataPoints={operatingExpensesIndicator.data}
            groupBy={operatingExpensesIndicator.groupBy}
          />
        )}
        {!!cogsIndicator?.data.length && (
          <LineChart title={cogsIndicator.indicatorName} dataPoints={cogsIndicator.data} groupBy={cogsIndicator.groupBy} />
        )}
        {!!grossRevenueIndicator?.data.length && (
          <LineChart
            title={grossRevenueIndicator.indicatorName}
            dataPoints={grossRevenueIndicator.data}
            groupBy={grossRevenueIndicator.groupBy}
          />
        )}
        {!!itdaIndicator?.data.length && (
          <LineChart title={itdaIndicator.indicatorName} dataPoints={itdaIndicator.data} groupBy={itdaIndicator.groupBy} />
        )}
        {!!ebitdaIndicator?.data.length && (
          <BarChart title={ebitdaIndicator.indicatorName} dataPoints={ebitdaIndicator.data} groupBy={ebitdaIndicator.groupBy} />
        )}
        {!!netRevenueIndicator?.data.length && (
          <LineChart
            title={netRevenueIndicator.indicatorName}
            dataPoints={netRevenueIndicator.data}
            groupBy={netRevenueIndicator.groupBy}
          />
        )}
        {!!netIncomeIndicator?.data.length && (
          <BarChart title={netIncomeIndicator.indicatorName} dataPoints={netIncomeIndicator.data} groupBy={netIncomeIndicator.groupBy} />
        )}
      </ChartsBox>
    </Container>
  );
};

Charts.propTypes = {
  chartsData: arrayOf(any).isRequired
};

export default Charts;
