import React, { useCallback, useContext, useEffect } from 'react';
import { RegisterUserContext } from 'context/registerUser.context';
import { getIndustries, createCompany } from 'state/actions/companyActions';
import { useStatus, useDispatch, useCompanies } from 'hooks';
import { dispatchErrorToast } from 'utils/uiUtils';
import {
  Box,
  Card,
  Button,
  Typography,
  styled,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  TextField,
  Link
} from '@mui/material';
import { REJECTED } from 'constants/actionStatusConstants';
import { func } from 'prop-types';
import { COMPANY_SIZES } from 'constants/constants';

const CardCompanyInfo = styled(Card)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: '4.3rem 3.3rem 3.2rem 3.3rem',
  width: '55rem'
});

const Container = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  height: 'calc(100% - 9rem)'
});

const FormTitle = styled(Typography)({
  paddingRight: '6rem',
  paddingLeft: '6rem',
  textAlign: 'center'
});

const FormContainer = styled(Box)({
  marginTop: '6rem'
});

const SelectInputLabel = styled(InputLabel)({
  fontSize: '1.6rem'
});

const CustomOutlinedInput = styled(OutlinedInput)({
  fontSize: '1.6rem'
});

const CustomSelect = styled(Select)({
  fontSize: '1.6rem'
});

const CustomMenuItem = styled(MenuItem)({
  fontSize: '1.6rem'
});

const CustomTextField = styled(TextField)({
  fontSize: '1.6rem',
  marginBottom: '5rem'
});

const ErrorMessage = styled(Typography)({
  fontSize: '1.6rem',
  color: '#EB5757',
  paddingTop: '0.6rem',
  paddingBottom: '0.6rem',
  marginTop: '-3.7rem',
  width: '100%'
});

const FooterBar = styled(Box)({
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column'
});

const GoBackLink = styled(Link)(({ theme }) => ({
  color: theme.palette.black.main,
  cursor: 'pointer',
  fontSize: '1.8rem',

  textDecorationColor: theme.palette.black.main
}));

const ContinueButton = styled(Button)({
  marginBottom: '2rem'
});

const CompanyForm = ({ onSubmit, goBack }) => {
  const { company, setCompany } = useContext(RegisterUserContext);
  const getIndustriesRequest = useDispatch(getIndustries);
  const { industries } = useCompanies();
  const { status: createCompanyStatus, error: createCompanyError } = useStatus(createCompany);

  useEffect(() => {
    getIndustriesRequest();
  }, [getIndustriesRequest]);

  const handleChange = prop => event => {
    setCompany({ ...company, [prop]: event.target.value });
  };

  const validateFormData = useCallback(() => {
    const errors = [];
    if (!company.name) {
      errors.push('Company name is required.');
    }
    if (!company.mainIndustryId) {
      errors.push('Industry is required');
    }
    if (!company.size) {
      errors.push('Company size is required');
    }
    if (errors.length) {
      dispatchErrorToast(errors.join('. '));
    }
    return !errors.length;
  }, [company]);

  const onContinue = useCallback(() => {
    if (validateFormData()) {
      if (company.website === '') delete company.website;
      onSubmit(company);
    }
  }, [company, validateFormData, onSubmit]);

  return (
    <Container>
      <CardCompanyInfo>
        <FormTitle variant="h1">Tell us a bit more about the Company</FormTitle>
        <FormContainer component="form" fullWidth>
          <FormControl disabled fullWidth variant="outlined">
            <SelectInputLabel htmlFor="outlined-adornment-edit">Company Name</SelectInputLabel>
            <CustomOutlinedInput
              id="outlined-adornment-edit"
              type="text"
              value={company.name}
              onChange={handleChange('name')}
              label="Company Name"
            />
          </FormControl>
          <FormControl fullWidth sx={{ mt: '1.25vw' }}>
            <SelectInputLabel id="industry-select-label">Main Industry</SelectInputLabel>
            <CustomSelect
              labelId="industry-select-label"
              id="industry-select"
              value={company.mainIndustryId}
              label="Main Industry"
              onChange={handleChange('mainIndustryId')}
              MenuProps={{ PaperProps: { sx: { maxHeight: '20.83vw' } } }}
            >
              {industries.map((industry, index) => (
                <CustomMenuItem key={index} value={industry.id}>
                  {industry.name}
                </CustomMenuItem>
              ))}
            </CustomSelect>
          </FormControl>
          <FormControl fullWidth sx={{ mt: '1.25vw' }}>
            <SelectInputLabel id="company-size-select-label">Company size</SelectInputLabel>
            <CustomSelect
              labelId="company-size-select-label"
              id="company-size-select"
              value={company.size}
              label="Company size"
              onChange={handleChange('size')}
            >
              {COMPANY_SIZES.map(size => (
                <CustomMenuItem key={`${size}`} value={`${size}`}>
                  {size}
                </CustomMenuItem>
              ))}
            </CustomSelect>
          </FormControl>
          <CustomTextField
            id="outlined-website"
            label="Website"
            fullWidth
            value={company.website}
            onChange={handleChange('website')}
            sx={{ mt: '1.25vw' }}
          />
          <FooterBar>
            {createCompanyStatus === REJECTED && <ErrorMessage>{createCompanyError}</ErrorMessage>}
            <ContinueButton variant="contained" fullWidth onClick={onContinue}>
              Continue
            </ContinueButton>
            <GoBackLink onClick={goBack}>Go back</GoBackLink>
          </FooterBar>
        </FormContainer>
      </CardCompanyInfo>
    </Container>
  );
};

CompanyForm.propTypes = {
  onSubmit: func.isRequired,
  goBack: func.isRequired
};

export default CompanyForm;
