import React from 'react';

import routesPaths from 'constants/routesPaths';
import LoginPage from 'pages/LoginPage';
import RegisterUserPage from 'pages/RegisterUserPage';
import NotFoundPage from 'pages/NotFoundPage';
import DashboardPage from 'pages/DashboardPage';
import LandingPage from 'pages/LandingPage';
import RecoverPasswordPage from 'pages/RecoverPasswordPage';
import ResetPasswordPage from 'pages/ResetPasswordPage';

const routes = [
  {
    path: [routesPaths.index, routesPaths.aboutUs],
    component: <LandingPage />,
    exact: true
  },
  {
    path: routesPaths.login,
    component: <LoginPage />
  },
  {
    path: routesPaths.registerUser,
    component: <RegisterUserPage />
  },
  {
    path: routesPaths.dashBoard,
    component: <DashboardPage startingTab="Dashboard" />,
    private: true
  },
  {
    path: routesPaths.dashBoard_deals,
    component: <DashboardPage startingTab="My Deals" />,
    private: true
  },
  {
    path: routesPaths.dashBoard_financial,
    component: <DashboardPage startingTab="Financial Data Upload" />,
    private: true
  },
  {
    path: routesPaths.recoverPassword,
    component: <RecoverPasswordPage />
  },
  {
    path: routesPaths.resetPassword,
    component: <ResetPasswordPage />
  },
  {
    component: <NotFoundPage />
  }
];

export default routes;
