import React from 'react';
import { Card, Box, Typography, Button, styled } from '@mui/material';
import StepsIndicator from 'components/steps/StepsIndicator';
import { func } from 'prop-types';

const steps = [
  'Choose which information you want to share with other parties.',
  'Select the Company and the Email of an existing user you want to share your information with.',
  'The user you have chosen accepts the deal'
];

const Container = styled(Box)({
  width: '100%',
  marginTop: '5rem',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column'
});

const NoDealsTextCentralBox = styled(Box)({
  width: '100%',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
});

const NoDealsAddCompaniesText = styled(Typography)({
  marginTop: '3.2rem',
  marginBottom: '3.2rem',
  fontSize: '2.4rem',
  maxWidth: '40rem',
  textAlign: 'center'
});

const StartDealButton = styled(Button)(({ theme }) => ({
  color: 'white',
  backgroundColor: theme.palette.primary.main,
  height: '2.5vw',
  paddingLeft: '1.4rem',
  paddingRight: '1.4rem'
}));

const NoDealsInstructionsCard = styled(Card)({
  width: '100%',
  marginTop: '8rem',
  padding: '4.1rem',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column'
});

const IntructionsTitleText = styled(Typography)({
  fontSize: '2.4rem',
  width: '100%',
  fontWeight: 'bold',
  textAlign: 'center',
  paddingBottom: '3.1rem'
});

const NoDeals = ({ onButtonPress }) => {
  return (
    <Container>
      <NoDealsTextCentralBox>
        <Typography variant="h1"> There’s no active Deals yet </Typography>
        <NoDealsAddCompaniesText>
          Invite other Companies to see your information.
        </NoDealsAddCompaniesText>
        <StartDealButton variant="contained" onClick={onButtonPress}>
          START A NEW DEAL
        </StartDealButton>
      </NoDealsTextCentralBox>
      <NoDealsInstructionsCard>
        <IntructionsTitleText> How the deals work </IntructionsTitleText>
        <StepsIndicator steps={steps} currentStep={3} fontSize="1.8rem" />
      </NoDealsInstructionsCard>
    </Container>
  );
};

NoDeals.propTypes = {
  onButtonPress: func.isRequired
};

export default NoDeals;
